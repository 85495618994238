import { async } from '@angular/core/testing';
import { SnackbarService } from './../../../services/snackbar/snackbar.service';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { InvitaionsService } from '../../../services/professionnel/invitaions.service';
import { AuthProfessionnelService } from '../../../services/professionnel/auth-professionnel.service';
import{PatientDataService} from '../../../services/shared-data/patient-data.service'
import { UpdProfilPatientService } from 'src/app/views/services/patient/upd-profil-patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DemandePatService } from 'src/app/views/services/patient/demande-pat.service';
import Swal from 'sweetalert2'
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator'; // Import MatPaginator
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/partials/dialog/dialog.component';
import { trigger, transition, animate, state, style } from '@angular/animations';
import { NotificationService } from 'src/app/partials/notifications/notification.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css'],
  animations: [
    // Define the animation trigger
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })), // Hidden state
      transition(':enter', animate('500ms', style({ opacity: 1 }))), // Transition to visible
    ]),
  ],
})
export class InvitationsComponent implements OnInit,OnChanges {
  @Input() public value: string;
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['name', 'adresse', 'email', 'tel', 'invitation'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator; // Add MatPaginator
  loadingIds = new Set<string>(); // Define loadingIds property

  id: any;
  allFrormsNumber:any
  invts:any
  contacts:any
  invt:any
  test:true
  i=1;
  filtredInvts:any
  PatientID:any
  index:any
  mesgEmpty: boolean=false;
  listPatient:any;
  p:number;
  friend = true;
  showPendingInvitations: boolean = false;
  isfrench:any
  pat={

    name: '',
    lastname: '',
    birthday: '',
    adresse: '',
    tel: '',
    email: '',
 
 
    gender: '',
    photo: '',
 
    added_date: '',
    _id:""
  
  }
  limit:any;
  skip:any;
  spinerListe=true;
  constructor(private _patient:UpdProfilPatientService,
    private snackBar:MatSnackBar,
    public dialog: MatDialog,
    private DemandeService: DemandePatService,
    private invservice:InvitaionsService,
    private authPro: AuthProfessionnelService ,
    private PatData:PatientDataService,
    private snackbarService: SnackbarService ,
    private notificationService: NotificationService
    
    ) {
    this.id = this.authPro.geid() 
   }

   ngOnInit(): void {
    this.geAllPatients();
  }

  geAllPatients(){
  
    this._patient.getAllPatient().subscribe((res)=>{
      console.log(res)
      if (res) {
        // Assume res contains your list of patients
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; // Set paginator

      }
  
        })
 
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  acceptInvt(body: any) {
    this.invservice.acceptInvts(this.id, body).subscribe(
      (response) => {
        this.snackbarService.showSuccess('Invite accepted');
      },
      (error) => {
        this.snackbarService.showError('Failed to accept invite');
      }
    );
  }
  

  ngOnChanges(value) {
    this.filterItem(value)
  }
  filterItem(value) {
    this.listPatient = this.filtredInvts.filter(i => {
      return (
        i.name.toLowerCase().includes(value.toLowerCase()) ||
        i.lastname.toLowerCase().includes(value.toLowerCase()) 

      )
    })
 }

  
getIdPat(idPat){
this.PatData.GetId(idPat)
}

onMouseEnter(event: any): void {
  event.target.click();
  
  
}
details(i){

  this.index=i
  this.pat=(this.invts[i].patient)

    
}
addPatient(id: any, row: any) {
  let obj = {
    doctor: localStorage.getItem('id_Pro'),
    patient: id,
  };

  // Update the invitation state of the clicked row to 'invitation_pending' immediately
  row.invitationState = 'invitation_pending';

  this.DemandeService.AddPatient(obj).subscribe(
    (res) => {
      if (res) {
        // Display a success notification
        this.notificationService.showSuccess(
          localStorage.getItem('langauage') === 'fr'
            ? 'Invitation a été envoyée'
            : 'Invitation has been sent'
        );

        // Remove the patient from the list
        var indexE = this.listPatient.findIndex((s) => s._id === id);
        this.listPatient.splice(indexE, 1);
      } else {
        // Display an error notification
        this.notificationService.showError(
          localStorage.getItem('langauage') === 'fr'
            ? 'Invitation non envoyée'
            : 'Invitation not sent'
        );

        // Reset the invitation state to 'not_invited' in case of failure
        row.invitationState = 'not_invited';
      }
    },
    (error) => {
      console.error('Error:', error);
      // Display an error notification
      this.notificationService.showError('An error occurred');

      // Reset the invitation state to 'not_invited' in case of failure
      row.invitationState = 'not_invited';
    }
  );
}


getButtonBackgroundColor(invitationState: string): string {
  switch (invitationState) {
    case 'invitation_accepted':
      return 'green';
    case 'invitation_pending':
      return '#cca42a'; 
    case 'not_invited':
      return '';
    default:
      return '';
  }
}

getButtonIcon(invitationState: string): string {
  switch (invitationState) {
    case 'invitation_accepted':
      return 'check_circle';
    case 'invitation_pending':
      return 'hourglass_empty';
    case 'not_invited':
      return 'send';
    default:
      return '';
  }
}

getButtonText(invitationState: string): string {
  const language = localStorage.getItem('langauage');

  switch (invitationState) {
    case 'invitation_accepted':
      return language === 'fr' ? 'Acceptée' : 'Accepted';

    case 'invitation_pending':
      return language === 'fr' ? 'En attente' : 'Pending';

    case 'not_invited':
      return language === 'fr' ? 'Envoyer' : 'Send';

    default:
      return '';
  }
}



  getPage(p:any) {

    this.limit=p*5-5;
    this.skip=p*5-1;
  



  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '100%',
      data: {
        title:  this.isfrench =localStorage.getItem('langauage') ==='fr'
        ?'Créer un Compte Patient'
        :'Create Patient Account',
        message: 'Dialog Message'
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  toggleInvitations() {
    this.showPendingInvitations = !this.showPendingInvitations;
    if (this.showPendingInvitations) {
      this.dataSource.filter = 'invitation_pending';
      this.dataSource.filterPredicate = (data, filter) => {
        const dataString = (data.invitationState && data.invitationState.toLowerCase()) || '';
        const filterString = filter;
        return dataString.indexOf(filterString) !== -1;
      };
    } else {
      this.dataSource.filter = '';
    }
  }

  
}
