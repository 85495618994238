<div class="forgot-password-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Forgot Password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForm()">
            <div class="choix">
                <mat-button-toggle-group appearance="legacy"
                  #group="matButtonToggleGroup" [value]="selectedVal" (change)="onValChange(group.value)">
                  <mat-button-toggle 
                    value="doctor" (click)="onReset()" class="pro">Professionnel
                  </mat-button-toggle>
                  <mat-button-toggle 
                    value="patient" (click)="onReset()" class="pat"> Patient 
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
          <mat-form-field appearance="fill">
            <mat-label>Email Address</mat-label>
            <input matInput formControlName="email" type="email" required>
            <mat-error class="email-error" *ngIf="forgotPasswordForm.get('email').hasError('required')">
                Email is required
              </mat-error>
              <mat-error class="email-error" *ngIf="forgotPasswordForm.get('email').hasError('email')">
                Invalid email format
              </mat-error>          </mat-form-field>
        
              <button
              mat-raised-button
              color="primary"
              type="button"
              class="login-button"
              (click)="submitForm()"
              [disabled]="forgotPasswordForm.get('email').invalid"
            >
            Reset Password
            </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  