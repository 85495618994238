import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetService } from '../../services/password/password-reset.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  userType: string = '';
  showPassword: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordResetService: PasswordResetService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      newPassword: ['', [
        Validators.required, Validators.minLength(8), 
        Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\]+$/),]],     
      confirmPassword: ['', [Validators.required]],
    }, { validator: this.passwordsMatchValidator });

    // You can use ActivatedRoute to retrieve parameters from the URL
    this.route.params.subscribe(params => {
      this.userType = params['userType'];
      const userId = params['userId'];
      const token = params['token'];
      // You can now use userId and token to verify the reset request
    });
  }

  // Custom validator function to check if passwords match
  passwordsMatchValidator(formGroup: FormGroup) {
    const newPassword = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (newPassword === confirmPassword) {
      return null; // Passwords match, no error
    } else {
      return { passwordsNotMatch: true }; // Passwords don't match, return an error
    }
  }

  private handleResetResult(result: string) {
    switch (result) {
      case 'password_reset_successful':
        this.router.navigate(['/password-reset-result/success']);
        break;
      case 'token_not_found':
        this.router.navigate(['/password-reset-result/token-not-found']);
        break;
      case 'token_expired':
        this.router.navigate(['/password-reset-result/token-expired']);
        break;
      case 'failure':
        this.router.navigate(['/password-reset-result/failure']);
        break;
      default:
        this.router.navigate(['/password-reset-result/failure']);
        break;
    }
  }
  resetPassword() {
    if (this.resetPasswordForm.valid) {
      const userId = this.route.snapshot.params['userId'];
      const token = this.route.snapshot.params['token'];

      this.passwordResetService.resetPassword(this.userType, userId, token, this.resetPasswordForm.get('newPassword').value)
        .subscribe(
          (response) => {
            // Reset successful, navigate to a success page or perform other actions
            this.handleResetResult(response.message)},
          (error) => {
            console.error('Error resetting password:', error);
          }
        );
    } else {
      // Handle form validation errors
      console.error('Form is not valid.');
    }
  }

  togglePasswordVisibility(controlName: string) {
    this.showPassword = !this.showPassword;
    const inputElement = document.querySelector(`[formControlName="${controlName}"] input`);
  
    if (inputElement) {
      inputElement.setAttribute('type', this.showPassword ? 'text' : 'password');
    }
  }
}
