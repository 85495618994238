import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Professional } from '../../interfaces/professionnel.interface';
import { AuthProfessionnelService } from '../../services/professionnel/auth-professionnel.service';
import { UpdProfilProServiceService } from '../../services/professionnel/upd-profil-pro-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-profil-pro',
  templateUrl: './edit-profil-pro.component.html',
  styleUrls: ['./edit-profil-pro.component.css']
})
export class EditProfilProComponent implements OnInit, OnDestroy {
  professionnel: Professional;
  id: any;
  dataProf: Professional;
  updateForm: FormGroup;
  messageSuccess = '';
  private obsGet: Subscription;
  private obsUpd: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private updateService: UpdProfilProServiceService,
    private router: Router,
    public dialogRef: MatDialogRef<EditProfilProComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel,
    private authPro: AuthProfessionnelService
  ) {
    this.id = this.authPro.geid();
    this.professionnel = this.authPro.getUsername();
  }

  ngOnInit(): void {
    this.updateForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.email]],
      adeli: ['', Validators.required],
      rpps: ['', Validators.required],
      fax: ['', Validators.required],
      tel: ['', Validators.required],
      adresse: ['', Validators.required],
      birthday: [''],
      gender: [''],
      photo: [''],
      job: [''],
      added_date: ['']
    });

    this.obsGet = this.updateService.getProf(this.id).subscribe(response => {
      this.dataProf = response;
      this.updateForm.patchValue(this.dataProf);
    });
  }

  onUpdate(): void {
    if (this.updateForm.invalid) {
      return;
    }

    this.obsUpd = this.updateService.updateprof(this.id, this.updateForm.value).subscribe(
      response => {
        this.snackBar.open('Profile updated', '×', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: 'success'
        });
        this.dialogRef.close(true);
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open('Error updating profile', '×', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: 'error'
        });
      }
    );
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    if (this.obsGet) {
      this.obsGet.unsubscribe();
    }
    if (this.obsUpd) {
      this.obsUpd.unsubscribe();
    }
  }
}

export class DialogModel {
  constructor(public title: string, public message: string) {}
}
