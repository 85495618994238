<div class="register">
  <div class="mat-tab-content">
    <mat-card class="auth-card modern-card">
      <mat-card-content class="mat-card-content">
        <!-- Header with Title -->
        <div class="auth-header">
          <h1>{{ "AUTH.SIGNUP" | translate }}</h1>
          <p class="subtitle">{{ "AUTH.CREATE_ACCOUNT" | translate }}</p>
        </div>

        <!-- Role Selection Toggle -->
          <mat-button-toggle-group
            class="auth-toggle-group"
            aria-label="User Role Selection"
            [(value)]="selectedVal2"
            (change)="onValChange2(selectedVal2)"
          >
            <mat-button-toggle value="Professionnel" (click)="onReset()">
              <i class="fa fa-user-md"></i> <!-- FontAwesome Icon -->
              <span>{{ "AUTH.PROF" | translate }}</span>
            </mat-button-toggle>
            <mat-button-toggle value="Patient" (click)="onReset()">
              <i class="fa fa-user-injured"></i> <!-- FontAwesome Icon -->
              <span>{{ "AUTH.PAT" | translate }}</span>
            </mat-button-toggle>
          </mat-button-toggle-group>

        <!-- Forms -->
        <div class="auth-form" [ngSwitch]="selectedVal2">
          <!-- Professional Form -->
          <div *ngSwitchCase="'Professionnel'" class="form-card">
            <form [formGroup]="registerFormPro" (ngSubmit)="registerPro(registerFormPro, 2)">
              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.NOM" | translate }}</mat-label>
                  <input matInput formControlName="name" />
                  <mat-error *ngIf="f.name.errors?.required">{{ "Nom obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.PRENOM" | translate }}</mat-label>
                  <input matInput formControlName="lastname" />
                  <mat-error *ngIf="f.lastname.errors?.required">{{ "Prénom obligatoire" }}</mat-error>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.DATEN" | translate }}</mat-label>
                  <input matInput type="date" formControlName="birthday" />
                  <mat-error *ngIf="f.birthday.errors?.required">{{ "Date obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.METIER" | translate }}</mat-label>
                  <mat-select formControlName="job">
                    <mat-option *ngFor="let job of jobs" [value]="job.viewValue">
                      {{ job.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
                  <input matInput formControlName="adresse" />
                  <mat-error *ngIf="f.adresse.errors?.required">{{ "Adresse obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>FAX</mat-label>
                  <input matInput type="number" formControlName="fax" />
                  <mat-error *ngIf="f.fax.errors?.required">{{ "Fax obligatoire" }}</mat-error>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                  <input matInput type="number" formControlName="tel" />
                  <mat-error *ngIf="f.tel.errors?.required">{{ "Téléphone obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>RPPS</mat-label>
                  <input matInput type="number" formControlName="rpps" />
                  <mat-error *ngIf="f.rpps.errors?.required">{{ "RPPS obligatoire" }}</mat-error>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.EMAIL" | translate }}</mat-label>
                  <input matInput type="email" formControlName="email" />
                  <mat-error *ngIf="f.email.errors?.required">{{ "Email obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.TITRE" | translate }}</mat-label>
                  <mat-select formControlName="title">
                    <mat-option *ngFor="let titre of titres" [value]="titre.viewValue">
                      {{ titre.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-footer">
                <button mat-raised-button color="primary" [disabled]="registerFormPro.invalid">
                  {{ "AUTH.SIGNUP" | translate }}
                </button>
              </div>
            </form>
          </div>

          <!-- Patient Form -->
          <div *ngSwitchCase="'Patient'" class="patient-form-card">
            <form [formGroup]="registerFormPat" (ngSubmit)="registerPat(registerFormPat, 1)">
              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.NOM" | translate }}</mat-label>
                  <input matInput formControlName="name" />
                  <mat-error *ngIf="fPat.name.errors?.required">{{ "Nom obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.PRENOM" | translate }}</mat-label>
                  <input matInput formControlName="lastname" />
                  <mat-error *ngIf="fPat.lastname.errors?.required">{{ "Prénom obligatoire" }}</mat-error>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
                  <input matInput formControlName="adresse" />
                  <mat-error *ngIf="fPat.adresse.errors?.required">{{ "Adresse obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>SSN</mat-label>
                  <input matInput formControlName="ssn" />
                  <mat-error *ngIf="fPat.ssn.errors?.required">{{ "SSN obligatoire" }}</mat-error>
                </mat-form-field>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.DATEN" | translate }}</mat-label>
                  <input matInput type="date" formControlName="birthday" />
                  <mat-error *ngIf="fPat.birthday.errors?.required">{{ "Date obligatoire" }}</mat-error>
                </mat-form-field>
                <div class="form-field">
                  <mat-label>{{ "AUTH.SEXE" | translate }}</mat-label>
                  <mat-radio-group formControlName="gender">
                    <mat-radio-button value="homme">{{ "AUTH.SEXEH" | translate }}</mat-radio-button>
                    <mat-radio-button value="femme">{{ "AUTH.SEXEF" | translate }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="form-row">
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.EMAIL" | translate }}</mat-label>
                  <input matInput type="email" formControlName="email" />
                  <mat-error *ngIf="fPat.email.errors?.required">{{ "Email obligatoire" }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="form-field">
                  <mat-label>{{ "AUTH.MOTDEPASS" | translate }}</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                  <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                </mat-form-field>
              </div>

              <div class="form-footer">
                <button mat-raised-button color="primary" [disabled]="registerFormPat.invalid">
                  {{ "AUTH.INSCRIRE" | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>

        <!-- Already Have Account -->
        <div class="already-account">
          <p>
            {{ "AUTH.HAVE_ACCOUNT" | translate }}
            <a routerLink="/login">{{ "AUTH.SIGNIN" | translate }}</a>
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>