<button class="custom-button" [ngClass]="buttonClass" (click)="onClick.emit()" [attr.data-toggle]="dataToggle" [attr.data-target]="dataTarget">
  <i [ngClass]="iconClass"></i>
  <ng-content></ng-content>
</button>



<!-- ************************use*******************  -->

<!-- 
<app-custom-button
  [dataToggle]="'modal'"
  [dataTarget]="'#exampleModal'"
  [buttonClass]="'custom-class'"
  [iconClass]="'fa-regular fa-share-from-square'"
  (onClick)="getFormId(form._id)"
>
  {{ 'FORMS.AFFECTFORM' | translate }}
</app-custom-button> -->
