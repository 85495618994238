import { TranslationService } from './../../views/services/translation/translation.service';
import { Component, OnDestroy, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthComponent } from '../../views/shared-components/auth/auth.component';
import { AuthProfessionnelService } from '../../views/services/professionnel/auth-professionnel.service'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-front-layout',
  templateUrl: './front-layout.component.html',
  styleUrls: ['./front-layout.component.css']
})
export class FrontLayoutComponent implements OnInit, OnDestroy {
  result: any;
  @Output() public sidenavToggle = new EventEmitter();
  status = false
  isResponsive: boolean = false;
  isSmallScreen = false;

  whois: boolean
  guser;
  currentLang = 'fr';
  readonly languages = [
    { value: 'en', label: 'English', flagIcon: '../../../assets/flags/eng.png' },
    { value: 'fr', label: 'Français', flagIcon: '../../../assets/flags/fr.png' },
  ];
  languageSelect = 'fr'; // Set the initial selected language
  dialogRef: MatDialogRef<AuthComponent> | null = null; // Reference to the dialog





  token: any = localStorage.getItem('token_Pro')
  tokenPat: any = localStorage.getItem('token_Pat')
  constructor(
    private breakpointObserver: BreakpointObserver,
    private authPro: AuthProfessionnelService, 
    public dialog: MatDialog, 
    ngZone: NgZone,
    private  translationService:TranslationService
    ) {
    this.isLoggidIn();
    if (localStorage.getItem('langauage') == null) {
      this.languageSelect = 'fr'
    } else {
      this.languageSelect = localStorage.getItem('langauage')
    }
  }
  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('langauage');

    // Set the initial value for languageSelect based on local storage
    this.languageSelect = storedLanguage || 'fr'; // Use 'fr' as the default language if not found in local storage

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        this.isSmallScreen = result.matches;
      });
  }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
  
    // Customize the dialog's configuration
    dialogConfig.width = 'auto'; // Set the height as a percentage of the viewport height

    dialogConfig.height = '80%'; // Set the height as a percentage of the viewport height
    dialogConfig.autoFocus = true; // Focus the first input element in the dialog
  
    // Open the dialog and store the reference
    this.dialogRef = this.dialog.open(AuthComponent, dialogConfig);
  }
  

  toggleResponsive() {
    this.isResponsive = !this.isResponsive;
  }

  isLoggidIn() {
    if (this.token) {
      this.status = true
      this.whois = true
    }
    if (this.tokenPat) {
      this.status = true
      this.whois = false
    }

    if (this.tokenPat = "") {
      this.status = false
    }
    if (this.token = "") {
      this.status = false

    }




  }

  onLangChange(currentLang: string) {
    localStorage.setItem('langauage', currentLang);
    this.translationService.useLang(currentLang);
  }

  // Helper method to get the flag icon based on the selected language
  getFlagIcon(language: string): string {
    const selectedLang = this.languages.find((lang) => lang.value === language);
    return selectedLang ? selectedLang.flagIcon : '';
  }

  // Helper method to get the language label based on the selected language
  getLanguageLabel(language: string): string {
    const selectedLang = this.languages.find((lang) => lang.value === language);
    return selectedLang ? selectedLang.label : '';
  }
  ngOnDestroy(): void {
    // Close the dialog when the component is destroyed
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }


}
