<mat-form-field class="profile-detail">
  <mat-label>{{ fieldName | titlecase }}</mat-label>
  <input matInput [disabled]="!editing || !editable" [formControl]="control">
  
  <button mat-icon-button *ngIf="editable && !editing" (click)="startEditing()">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-icon-button *ngIf="editable && editing" (click)="stopEditing()">
    <mat-icon>done</mat-icon>
  </button>
</mat-form-field>
