import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientFormsService } from 'src/app/views/services/patient/patient-forms.service';
import { Options } from 'ng5-slider/options';
import { PatientDataService } from 'src/app/views/services/shared-data/patient-data.service';
import { UpdProfilPatientService } from 'src/app/views/services/patient/upd-profil-patient.service';
import { Patient } from 'src/app/views/interfaces/patient.interface';
/**
 * Interface representing the BMI Result.
 */
interface BMIResult {
  bmi: number;
  interpretation: string;
}
/**
 * @title priview-form-doctor
 * @description priview-form-doctor representing the preview of the reponse patients.
 */
@Component({
  selector: 'app-priview-form-doctor',
  templateUrl: './priview-form-doctor.component.html',
  styleUrls: ['./priview-form-doctor.component.css'],
})
export class PriviewFormDoctorComponent implements OnInit {

  showList: boolean = false;
  idForm:any;
  idDoctor:any;
  idPatient:any;
  tryNumber:any
  tabRep :any;
  patientIMC:Number
  tabScore:any;
  favoriteSeason: string;
  questionCount: number = 0; // Initialize the count variable
  numbers: number[] = [];
  patientData:Patient
  BMIResult: BMIResult = {
    bmi: 0,
    interpretation: 'Unknown'
  };

  age : Number;
  isSliderDisabled: boolean = true; // Set to true to disable the slider initially

  photos = [
    { url: '../../../../../assets/imc/imc1.png', category: 'underweight' },
    { url: '../../../../../assets/imc/imc2.png', category: 'normalweight' },
    { url: '../../../../../assets/imc/imc3.png', category: 'overweight' },
    { url: '../../../../../assets/imc/imc4.png', category: 'obesity1' },
    { url: '../../../../../assets/imc/imc5.png', category: 'obesity2' }
  ];

  constructor( private patientDataService:PatientDataService,private router: ActivatedRoute,private PatForms:PatientFormsService , private updateservice:UpdProfilPatientService) {

   
   
    for (let index = 0; index < 10000; index++) {
      this.numbers.push(index);
    }
    this.idPatient=this.router.snapshot.paramMap.get('idPatient');
    this.idDoctor=this.router.snapshot.paramMap.get('idDocter');
    this.idForm=this.router.snapshot.paramMap.get('idForm');
    this.tryNumber=this.router.snapshot.paramMap.get('tryNumber');

   }

     /**
   * Generates options for the slider.
   * @param slider The slider element.
   * @returns Options for the slider.
   */

   sliderMakeOptions(slider): Options {
    /* console.log("llllll",slider) */
    return {
      floor: 10,
      ceil: 100,
      step: 10,
      showTicks: true,
 stepsArray:slider,  

}
   }
  ngOnInit(): void {
    this.PatForms.getRepdoctor(this.idPatient,this.idDoctor,this.idForm,this.tryNumber).subscribe((res)=>{
     console.log(this.idPatient,this.idDoctor,this.idForm,this.tryNumber)
      this.tabRep=res
      this.questionCount = this.tabRep.responses.length; // Assign the value of the length of responses array to the count variable

    })



     this.updateservice.getPatient(this.idPatient).subscribe((data)=>{
      this.patientData=data
      this.BMIResult = this.calculateBMI(this.patientData.weight,this.patientData.size)
      this.age= this.calculateAge(this.patientData.birthday)
     })



    
  }
    /**
   * Toggles the display of a list.
   */
  toggleList() {
    this.showList = !this.showList;
  }
  /**
   * Calculates BMI (Body Mass Index).
   * @param weight The weight of the patient.
   * @param height The height of the patient.
   * @returns The BMI result.
   */
  calculateBMI(weight: number | undefined, height: number | undefined): BMIResult {
    if (weight === undefined || height === undefined || weight <= 0 || height <= 0) {
      // Return a default BMIResult or handle invalid input case
      return this.BMIResult; // Returning default values
    }

    const heightInMeters = height / 100;
    const bmi = weight / (heightInMeters * heightInMeters);
    let interpretation = '';

    if (bmi < 18.5) {
      interpretation = 'Underweight';
    } else if (bmi >= 18.5 && bmi < 25) {
      interpretation = 'Normal weight';
    } else if (bmi >= 25 && bmi < 30) {
      interpretation = 'Overweight';
    } else {
      interpretation = 'Obese';
    }

    this.BMIResult = { bmi, interpretation };
    return this.BMIResult;
  }

 /**
   * Retrieves the photo based on BMI.
   * @returns The photo based on the BMI category.
   */
getPhotoByIMC() {
  if (this.BMIResult.bmi < 18.5) {
    return this.photos.find(photo => photo.category === 'underweight');
  } else if (this.BMIResult.bmi >= 18.5 && this.BMIResult.bmi < 25) {
    return this.photos.find(photo => photo.category === 'normalweight');
  } else if (this.BMIResult.bmi >= 25 && this.BMIResult.bmi < 30) {
    return this.photos.find(photo => photo.category === 'overweight');
  } else if (this.BMIResult.bmi >= 30 && this.BMIResult.bmi < 35) {
    return this.photos.find(photo => photo.category === 'obesity1');
  } else {
    return this.photos.find(photo => photo.category === 'obesity2');
  }
}

  /**
   * Calculates the age of the patient.
   * @param dateOfBirth The date of birth of the patient.
   * @returns The age of the patient.
   */

calculateAge(dateOfBirth: string): number {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

}
