export const jobData = [
    { value: 'Ana', viewValue: 'Anato.Cyto. Pathologie (37)' },
    { value: 'Ane', viewValue: 'Anesthésiologie-Réanimmation.Chirurgicale (02)' },
    { value: 'Car', viewValue: 'Cardiologie (03)' },
    { value: 'Chi1', viewValue: 'Chir thoracique et cardio-vasculaire (47)' },
    { value: 'Chi2', viewValue: 'Chir.maxillo-faciale, stomatologie (45)' },
    { value: 'Chi3', viewValue: 'Chir.plast reconstructrice (46)' },
    { value: 'Chi4', viewValue: 'Chir.viscérale et digestive (49)' },
    { value: 'Chi5', viewValue: 'Chirurgie Dentaire (19)' },
    { value: 'Chi6', viewValue: 'Chirurgie Dentaire spécialité O.D.F (36)' },
    { value: 'Chi7', viewValue: 'Chirurgie générale (04)' },
    { value: 'Chi8', viewValue: 'Chirurgie Infantile (43)' },
    { value: 'Chi9', viewValue: 'Chirurgie maxillo-faciale (44)' },
    { value: 'Chi10', viewValue: 'Chirurgie orale (69)' },
    { value: 'Chi11', viewValue: 'Chirurgie Orthopédique (41)' },
    { value: 'Chi12', viewValue: 'Chirurgie vasculaire (48)' },
    { value: 'Chi13', viewValue: 'Chirurgien-dentise spécialité C.O. (53)' },
    { value: 'Der', viewValue: 'Dermato Vénérologie (05)' },
    { value: 'Dir', viewValue: 'Directeur de laboratoire médecin' },
    { value: 'End', viewValue: 'Endocrinologie, métabolisme (42)' },
    { value: 'Fou1', viewValue: 'Fournisseurs Artisan : titre 1, titre 2 chap (61)' },
    { value: 'Fou2', viewValue: 'Fournisseurs Association : titre 1, titre 2 (62)' },
    { value: 'Fou3', viewValue: 'Fournisseur Optique médical : titre 2, cha (64)' },
    { value: 'Fou4', viewValue: 'Fournisseurs Orthèses : titre 2, chapitre 1 (63)' },
    { value: 'Fou5', viewValue: 'Fournisseurs Ortho prothèses : titre 2, chap (68)' },
    { value: 'Fou6', viewValue: 'Fournisseurs Podo-orthèses : titre 2, chapit (67)' },
    { value: 'Fou7', viewValue: 'Fournisseurs Prothèses oculaires et facials (66)' },
    { value: 'Fou8', viewValue: 'Chirurgie Dentaire spécialité O.D.F (36)' },
    { value: 'Fou9', viewValue: 'Fournisseurs Société : titre 1, titre 2 chap (60)' },
    { value: 'Gas', viewValue: 'Gastro-entérologie et Hépatologie (08)' },
    { value: 'Gén', viewValue: 'Génétique médicale (78)' },
    { value: 'Gér', viewValue: 'Gériatrie (34)' },
    { value: 'Gyn', viewValue: 'Gynécologie médicale (70)' },
    { value: 'Gyn', viewValue: 'Gynécologie Obstétrique (07)' },
    { value: 'Gyn', viewValue: 'Gynécologie obstétrique et médicale (79)' },
    { value: 'Hém', viewValue: 'Hématologie (71)' },
    { value: 'Inf', viewValue: 'Infirmier (e) (24)' },
    { value: 'LAB', viewValue: 'LABM et de cyto-phatologie (40)' },
    { value: 'LAB', viewValue: 'LABM polyvalent (39)' },
    { value: 'Lab', viewValue: 'Laboratoire d analyses médicales (30)' },
    { value: 'Mas', viewValue: 'Masseur-kinésithérapeute (26)' },
    { value: 'Méd', viewValue: 'Médecine Générale (01)' },
    { value: 'Méd', viewValue: 'Médecine interne (09)' },
    { value: 'Méd', viewValue: 'Médecine nucléaire (72)' },
    { value: 'Nép', viewValue: 'Néphrologie (35)' },
    { value: 'Neu', viewValue: 'Neuro-chirurgie (10)' },
    { value: 'Neu', viewValue: 'Neurologie (32)' },
    { value: 'Neu', viewValue: 'Neuropsychiatrie (17)' },
    { value: 'Obs', viewValue: 'Obstétrique (77)' },
    { value: 'Onc', viewValue: 'Oncologie médicale (73)' },
    { value: 'Onc', viewValue: 'Oncologie radiothérapique (74)' },
    { value: 'Oph', viewValue: 'Ophtalmologie (15)' },
    { value: 'Ort', viewValue: 'Orthophoniste (28)' },
    { value: 'Ort', viewValue: 'Orthoptiste (29)' },
    { value: 'Oto', viewValue: 'Oto-rhino-laryngologie (11)' },
    { value: 'Péd', viewValue: 'Pédiatrie (12)' },
    { value: 'Péd', viewValue: 'Pédicure-podologue (27)' },
    { value: 'Pha', viewValue: 'Pharmacien (50)' },
    { value: 'Pha', viewValue: 'Pharmacien mutualiste (51)' },
    { value: 'Pne', viewValue: 'Pneumologie (13)' },
    { value: 'Psy', viewValue: 'Psychiatrie (33)' },
    { value: 'Psy', viewValue: 'Psychiatrie de l enfant et de l adolescence (75)' },
    { value: 'Rad', viewValue: 'Radiologie (06)' },
    { value: 'Rad', viewValue: 'Radiothérapie (76)' },
    { value: 'Réa', viewValue: 'Réanimation médicale (20)' },
    { value: 'Réé', viewValue: 'Rééducation Réadaptent Fonctionnel (31)' },
    { value: 'Rhu', viewValue: 'Rhumatologie (14)' },
    { value: 'Sag', viewValue: 'Sage-femme (21)' },
    { value: 'San', viewValue: 'Santé publique et médecine sociale (80)' },
    { value: 'Spé', viewValue: 'Spécialiste en médecine générale avec diplôme (22)' },
    { value: 'Spé', viewValue: 'Spécialiste en médecine générale reconnu par (23)' },
    { value: 'Som', viewValue: 'Somatologie (18)' },
    { value: 'Uro', viewValue: 'Urologie (16)' },
    { value: 'autre', viewValue: 'autre' },
];

