import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  private baseUrl = environment.urlBackend; // Define your API URL in environment.ts

  constructor(private http: HttpClient) {}

  // Method to send a forgot password request to the backend
  sendForgotPasswordRequest(userType:string,email: string): Observable<any> {
    const body = { email: email };
    return this.http.post(`${this.baseUrl}${userType==='doctor'?'doctor':'patient'}/forgot-password`, body);
  }
}
