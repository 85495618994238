import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-reset-result',
  templateUrl: './password-reset-result.component.html',
  styleUrls: ['./password-reset-result.component.css']
})
export class PasswordResetResultComponent implements OnInit {
  message: string = '';
  result: string; // 

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.result = params['result']; // Get the result from route parameters
      this.setMessage(this.result);
      
      
    });
  }

  setMessage(result: string | null): void {
    switch (result) {
      case 'success':
        this.message = 'Password reset was successful.';
        break;
      case 'token-not-found':
        this.message = 'Token not found. Please request a new password reset link.';
        break;
      case 'token-expired':
        this.message = 'Token has expired. Please request a new password reset link.';
        break;
      case 'failure':
        this.message = 'Password reset failed. Please try again later.';
        break;
      case 'email-sent':
        this.message = 'Password reset email has been sent. Check your inbox.';
        break;
      case 'email-not-found':
        this.message = 'Email address not found. Please check your email and try again.';
        break;
      default:
        this.message = 'Unknown result.';
    }
  }
  
}
