import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
/**
 * @title NotificationService reusable Component
 * @description Service to display notifications using SweetAlert2.
 */
export class NotificationService {
  constructor() {}

   /**
   * Display a success notification message.
   * @param message - The message to be displayed.
   */
  showSuccess(message: string) {
    this.showNotification('success', message);
  }
/**
   * Display an error notification message.
   * @param message - The message to be displayed.
   */
  showError(message: string) {
    this.showNotification('error', message);
  }
  /**
   * Show a notification based on the provided type and message.
   * @param type - The type of notification ('success' or 'error').
   * @param message - The message to be displayed.
   * @private
   */
  private showNotification(type: 'success' | 'error', message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: type === 'success' ? 500 : 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }
}
