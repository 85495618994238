import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from '../../services/password/password-forgot.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public selectedVal="doctor";
  forgotPasswordForm: FormGroup;
  messageError:any;
  submitted = false;

  constructor(    private router: Router,
    private fb: FormBuilder,    private forgotPasswordService: ForgotPasswordService) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {}

  private handleResetResult(result: string) {
    switch (result) {
      case 'email_sent':
        this.router.navigate(['/password-reset-result/email-sent']);
        break;
      case 'patient_not_found':
        this.router.navigate(['/password-reset-result/email-not-found']);
        break;
      case 'doctor_not_found':
        this.router.navigate(['/password-reset-result/email-not-found']);
        break;
      default:
        this.router.navigate(['/password-reset-result/email-not-found']);
        break;
    }
  }
  submitForm() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.get('email').value;
      this.forgotPasswordService
      .sendForgotPasswordRequest(this.selectedVal,email)
      .subscribe(
        (response) => {
          this.handleResetResult(response.message)
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  public onValChange(val: any) {
    this.selectedVal = val;
    
  }
  onReset() {
    this.submitted = false;
  }
}
