<div class="result-container">
    <mat-card>
      <mat-card-content>
        <div class="result-message">
          <ng-container [ngSwitch]="result">
            <ng-container *ngSwitchCase="'email-sent'">
                <div class="success-message">
                  <mat-icon color="primary">check_circle</mat-icon>
                  <p>{{ message }}</p>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'email-not-found'">
                <div class="error-message">
                  <mat-icon color="warn">error</mat-icon>
                  <p>{{ message }}</p>
                </div>
              </ng-container>
            <ng-container *ngSwitchCase="'success'">
              <div class="success-message">
                <mat-icon color="primary">check_circle</mat-icon>
                <p>{{ message }}</p>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'token-not-found'">
              <div class="error-message">
                <mat-icon color="warn">error</mat-icon>
                <p>{{ message }}</p>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'token-expired'">
              <div class="error-message">
                <mat-icon color="warn">error</mat-icon>
                <p>{{ message }}</p>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'failure'">
              <div class="error-message">
                <mat-icon color="warn">error</mat-icon>
                <p>{{ message }}</p>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="error-message">
                <mat-icon color="warn">error</mat-icon>
                <p>{{ message }}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-card-content>
      <mat-card-actions class="center-button">
        <button mat-raised-button color="primary" routerLink="/">Back to Home</button>
      </mat-card-actions>
    </mat-card>
  </div>
  