import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';  // Import default from 'firebase/app'
import 'firebase/auth';  // Import auth service from 'firebase/auth'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$ = this.afAuth.authState;
  URL = environment.urlBackend;
  private Register = this.URL + 'patient/register';
  private jwtHelper = new JwtHelperService();  // Create instance of JwtHelperService
  private patientInfoUrl = `${this.URL}patient/profilData/`;

  constructor(private afAuth: AngularFireAuth, private http: HttpClient) {}

  signInWithEmail(email: string, password: string): Observable<any> {
    if (!email || typeof email !== 'string' || !password || typeof password !== 'string') {
      return throwError('Invalid email or password');
    }

    return new Observable(observer => {
      this.afAuth.signInWithEmailAndPassword(email.trim(), password)
        .then(userCredential => {
          userCredential.user?.getIdToken().then(token => {
            observer.next({ token });
            observer.complete();
          }).catch(error => {
            observer.error('Error retrieving token: ' + error.message);
          });
        })
        .catch(error => {
          observer.error('Error signing in with email: ' + error.message);
        });
    });
  }

  registerWithEmail(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return new Observable(observer => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          observer.next(userCredential);
          observer.complete();
        })
        .catch(error => {
          observer.error('Error registering with email: ' + error.message);
        });
    });
  }

  signInWithGoogle(): Observable<any> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return new Observable(observer => {
      this.afAuth.signInWithPopup(provider)
        .then(userCredential => {
          userCredential.user?.getIdToken().then(token => {
            observer.next({ token });
            observer.complete();
          }).catch(error => {
            console.error('Error retrieving token:', error.message);
            observer.error('Error retrieving token: ' + error.message);
          });
        })
        .catch(error => {
          console.error('Error signing in with Google:', error.message);
          observer.error('Error signing in with Google: ' + error.message);
        });
    });
  }
  

  signOut(): Observable<void> {
    return new Observable(observer => {
      this.afAuth.signOut()
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch(error => {
          observer.error('Error signing out: ' + error.message);
        });
    });
  }


  registerPatient(patientData: any): Observable<any> {
    return this.http.post<any>(this.Register, patientData);
  }
  
  

  decodeToken(token: string): any {
    return this.jwtHelper.decodeToken(token);
  }

  getPatientInfo(uid: string): Observable<any> {
    const token = localStorage.getItem('patient-token');  // Retrieve the token from localStorage
  
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  
    return this.http.get<any>(`${this.patientInfoUrl}${uid}`, { headers }).pipe(
      catchError(error => {
        console.error('Error fetching patient info from API:', error);
        return throwError(error);
      })
    );
  }
  
  
}
