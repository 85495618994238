import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Professional } from 'src/app/views/interfaces/professionnel.interface';
import { AuthProfessionnelService } from 'src/app/views/services/professionnel/auth-professionnel.service';
import { InvitaionsService } from 'src/app/views/services/professionnel/invitaions.service';
import { UpdProfilProServiceService } from 'src/app/views/services/professionnel/upd-profil-pro-service.service';
import { LoaderService } from 'src/app/views/services/loader/loader.service';
import { trigger, state, style, animate, transition, query } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslationService } from 'src/app/views/services/translation/translation.service';
@Component({
  selector: 'app-professionnel-layout',
  templateUrl: './professionnel-layout.component.html',
  styleUrls: ['./professionnel-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // Fade in when entering
      transition(':enter', [
        style({ opacity: 0 }), // Initial state
        animate('300ms ease-in', style({ opacity: 1 })) // Fading in
      ]),
      // Fade out when leaving
      transition(':leave', [
        style({ opacity: 1 }), // Initial state
        animate('300ms ease-out', style({ opacity: 0 })) // Fading out
      ])
    ])
  ], changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ProfessionnelLayoutComponent implements OnInit {
  isSidenavOpen: boolean = true;
  isOpen = true; // Initially open
  languageSelect = 'fr'; // Set the initial selected language
  readonly languages = [
    { value: 'en', label: 'English', flagIcon: '../../../assets/flags/eng.png' },
    { value: 'fr', label: 'Français', flagIcon: '../../../assets/flags/fr.png' },
  ];
  departments = [
    { value: 'etab1', label: 'Etab 1', icon: 'path/to/icon1.png' },
    { value: 'etab2', label: 'Etab 2', icon: 'path/to/icon2.png' },
    // Add more departments as needed
  ];
  @ViewChild('sidenav') sidenav: MatSidenav;


  toggleSidenav(sidenav: MatSidenav) {
    this.isSidenavOpen = !this.isSidenavOpen;
    if (this.isSidenavOpen) {
      sidenav.open();
    } else {
      sidenav.close();
    }
  }

  isOnline: boolean = navigator.onLine;

  professionnel: Professional
  updPatient: Professional
  submitted = false;
  hide: boolean = true;
  test: Professional
  id: any
  index = 0
  dataProf = {
    name: '',
    lastname: '',
    email: '',
    birthday: 0,
    tel: 0,
    id: '',
    adresse: '',
    fax: '',
    gender: '',
    photo: '',
    job: '',
    rpps: '',
    added_date: '',
    adeli: '',


  }
  constructor(
    public loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
    private invservice: InvitaionsService,
    private router: Router,
    private updateservice: UpdProfilProServiceService,
    private authPro: AuthProfessionnelService, ) 
    
    {

    this.id = this.authPro.geid()
    this.professionnel = this.authPro.getUsername()
    this.updateservice.getProf(this.id).subscribe(response =>
    this.dataProf = response)



  }

  ngOnInit(): void {
    
    const storedLanguage = localStorage.getItem('langauage');

    // Set the initial value for languageSelect based on local storage
    this.languageSelect = storedLanguage || 'fr'; // Use 'fr' as the default language if not found in local storage
  }

  logout() {
    localStorage.removeItem('token_Pro')
    this.router.navigate(['/'])
  }
  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }
  acceptInvt(body: any) {
    this.invservice.acceptInvts(this.id, body).subscribe(response => {
      this.snackBar.open("invite  accepted ", "×", {

        duration: 5000,

        // here specify the position

        verticalPosition: 'top',
        panelClass: 'success'

      });
    }, error => this.snackBar.open(" invite  accepted", "×", {

      duration: 5000,

      // here specify the position

      verticalPosition: 'top',
      panelClass: 'success'

    })
    )

  }
  trackByIndex(index: any): any {
    this.index = index
    return index;
  }

  sidebarAnimation = trigger('sidebarAnimation', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('300ms ease-in-out', style({ transform: 'translateX(0%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0%)' }),
      animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
    ]),
  ]);
  onLangChange(currentLang: string) {
    localStorage.setItem('langauage', currentLang);
    this.translationService.useLang(currentLang);
  }

  // Helper method to get the flag icon based on the selected language
  getFlagIcon(language: string): string {
    const selectedLang = this.languages.find((lang) => lang.value === language);
    return selectedLang ? selectedLang.flagIcon : '';
  }

  // Helper method to get the language label based on the selected language
  getLanguageLabel(language: string): string {
    const selectedLang = this.languages.find((lang) => lang.value === language);
    return selectedLang ? selectedLang.label : '';
  }

}
