import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvitationsRoutingModule } from './invitations-routing.module';
import { InvitationsComponent } from './invitations/invitations.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination'; // or the correct module import
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { PartialsModule } from 'src/app/partials/partials.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [InvitationsComponent],
  imports: [
    CommonModule,PartialsModule,
    InvitationsRoutingModule,MatIconModule,MatTableModule,MatFormFieldModule,MatPaginatorModule,MatInputModule,MatSortModule,MatCardModule,MatButtonModule,
    FormsModule,NgxPaginationModule,PartialsModule,TranslateModule
  ]
})
export class InvitationsModule { }
