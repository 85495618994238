export const environment = {
  production: true,
  urlBackend: 'http://185.104.172.119:3001/',
  // urlBackend:'http://localhost:3000/',
  // urlBackend:'http://38.242.195.210:3000/',
  // siteKey: '6LfMYJwpAAAAAAlH66TGcqCQX6Yh1NMVrueT9Cjy' // Add your reCAPTCHA Site Key here

  

};


