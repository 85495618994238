import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/firebase/auth.service';
import { CountryCodeService } from '../../services/Countries/country-code.service';
import { ActivatedRoute, Router } from '@angular/router';
import { COUNTRIES } from './../../helper/country-data';
import { Country } from '../../interfaces/country.interface';
import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2';
import { UpdProfilPatientService } from '../../services/patient/upd-profil-patient.service';
import { AuthPatientService } from '../../services/patient/auth-patient.service';
import { AuthProfessionnelService } from '../../services/professionnel/auth-professionnel.service';
import { MatDialog } from '@angular/material/dialog';
import { pastDateValidator } from '../../helper/pastDateValidator';
import { MustMatch } from '../../helper/MustMatch';
import {
  ssnLengthValidator,
  rppsLengthValidator,
  telFaxLengthValidator,
  sizeRangeValidator,
  weightRangeValidator,
} from '../../helper/custom-validators';
import { DatePipe } from '@angular/common';
import { Patient } from '../../interfaces/patient.interface';
import { Professional } from '../../interfaces/professionnel.interface';
import { jobData } from '../../interfaces/job-data';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit {
  phoneNumber = '';
  isLogin=true;
  countries: Country[] = COUNTRIES;

  selectedCountryCode: string;


  // user!:SocialUser;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  sig: SignaturePad;
  imgPad;
  acceptTerme = false;
  jobs = jobData;
  doctor:Professional;
  patient:Patient

  clear() {
    this.sig.clear();
  }
  titre = [
    { value: 'professionnel', viewValue: 'Pr' },
    { value: 'doctor', viewValue: 'Dr ' },
    { value: 'mester', viewValue: 'Mr' },
    { value: 'miss', viewValue: 'Mme ' },
  ]
  download() {
    //console.log(this.sig.toDataURL('image/svg+xml'));
    this.updateservice.uploadImage(this.sig.toDataURL('image/svg+xml')).subscribe((result) => {
      this.imgPad = result
      /*     console.log(result) */
    })
  }
  getErrorPassword(){}
  messageError: any;
  registerFormPro: FormGroup;
  registerFormPat: FormGroup;
  loginFormPro: FormGroup;
  loginFormPat: FormGroup;
  submitted = false;
  public selectedVal = "Professionnel";
  public selectedVal2 = "Professionnel";
  result: any;
  myDate = new Date();
  datatoken: any;
  dataResponse: any;
  status: any;
  action: any;
  showDetails: boolean;
  public showOverlay = true;
  hide: boolean = true;
  loading: boolean = false;
  points = [];
  
  signatureImage;
  public innerWidth: any;
  showImage(data) {
    this.signatureImage = data;
  }
  public onValChange(val: any) {
    this.selectedVal = val;
   //    console.log(this.selectedVal)
    this.status = (this.action + this.selectedVal)


  }

  
  hidePassword() {
    this.hide = !this.hide;
  }
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private AuthPatient: AuthPatientService,
    private AuthProfessionnel: AuthProfessionnelService,
    private updateservice: UpdProfilPatientService,
    private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe
  ) {}


  ngOnInit() {

    
    this.registerFormPro = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      birthday: ['', [Validators.required,pastDateValidator(),]],
      tel: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
    
      fax: ['', [
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
      adresse: ['', [Validators.required, Validators.minLength(2)]],
      job: ['', Validators.required],
      // adeli: ['', Validators.required, Validators.pattern("^[0-9]*$")],
      rpps: ['', [Validators.required, rppsLengthValidator()]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\s])[A-Za-z\d@$!%*?&\s]{8,}$/)
      ]], 

     confirmPassword: ['', [Validators.required,this.checkPassword ]],
      role: ['2',],
      consontement: [false, Validators.requiredTrue],
      title: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.registerFormPro.valueChanges.subscribe(() => {
      console.log('Form status: ', this.registerFormPro.status);
      console.log('Form value: ', this.registerFormPro.value);
    });

    this.registerFormPat = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      birthday: ['', [Validators.required,pastDateValidator()]],
      tel: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
      adresse: ['',[Validators.required, Validators.minLength(2)]],
      ssn: ['', [Validators.required, ssnLengthValidator()],Validators.pattern(/^\d{1,3}$/)],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\s])[A-Za-z\d@$!%*?&\s]{8,}$/)
      ]], 

    confirmPassword: ['', [Validators.required,this.checkPassword ]],
      consontement: [false, Validators.requiredTrue],
      mailConfirmation: [true,],
      weight: [false, [ Validators.required,
        weightRangeValidator(),
        Validators.pattern(/^\d+$/)]],
      size: [false, [ Validators.required,
        sizeRangeValidator(),
        Validators.pattern(/^\d+$/)]],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });



  }
  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }
  private initializeForms() {
    this.registerFormPro = this.formBuilder.group(
      {
        name: ['', [Validators.required, Validators.minLength(2)]],
        lastname: ['', [Validators.required, Validators.minLength(2)]],
        birthday: ['', [Validators.required, pastDateValidator()]],
        tel: [
          '',
          [
            Validators.required,
            telFaxLengthValidator(),
            Validators.pattern(/^\d+$/),
          ],
        ],
        fax: ['', [telFaxLengthValidator(), Validators.pattern(/^\d+$/)]],
        adresse: ['', [Validators.required, Validators.minLength(2)]],
        job: ['', Validators.required],
        rpps: ['', [Validators.required, rppsLengthValidator()]],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\s])[A-Za-z\d@$!%*?&\s]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
        role: ['2'],
        consontement: [false, Validators.requiredTrue],
        title: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.registerFormPat = this.formBuilder.group(
      {
        name: ['', [Validators.required, Validators.minLength(2)]],
        lastname: ['', [Validators.required, Validators.minLength(2)]],
        birthday: ['', [Validators.required, pastDateValidator()]],
        tel: [
          '',
          [
            Validators.required,
            telFaxLengthValidator(),
            Validators.pattern(/^\d+$/),
          ],
        ],
        adresse: ['', [Validators.required, Validators.minLength(2)]],
        ssn: [
          '',
          [
            Validators.required,
            ssnLengthValidator(),
            Validators.pattern(/^\d{1,3}$/),
          ],
        ],
        gender: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\s])[A-Za-z\d@$!%*?&\s]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
        consontement: [false, Validators.requiredTrue],
        weight: [
          '',
          [
            Validators.required,
            weightRangeValidator(),
            Validators.pattern(/^\d+$/),
          ],
        ],
        size: [
          '',
          [
            Validators.required,
            sizeRangeValidator(),
            Validators.pattern(/^\d+$/),
          ],
        ],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  clearSignature() {
    if (this.sig) this.sig.clear();
  }
  get f() { return this.registerFormPro.controls; }
  get fPat() { return this.registerFormPat.controls; }

  registerPro(info: any) {
    this.submitted = true;
    if (this.registerFormPro.invalid) return;

    const phoneWithCountryCode = this.prependCountryCode(
      this.registerFormPro.value.tel,
      this.selectedCountryCode
    );
    info.value.tel = phoneWithCountryCode;

    const doctorData = this.prepareDoctorData(info.value);
    this.AuthProfessionnel.registerProf(doctorData).subscribe(
      () => {
        this.showSuccessMessage();
        location.reload();
      },
      () => {
        this.showErrorMessage();
      }
    );
  }

  registerPat() {
    this.submitted = true;
    if (this.registerFormPat.invalid) {
      this.showErrorMessage();
      return;
    }

    const patientData = this.preparePatientData();
    this.AuthPatient.registerPatient(patientData).subscribe(
      () => {
        this.showSuccessMessage();
        location.reload();
      },
      () => {
        this.showErrorMessage();
      }
    );
  }

  private prepareDoctorData(formData: any): any {
    return {
      name: formData.name,
      lastname: formData.lastname,
      email: formData.email,
      birthday: this.datePipe.transform(formData.birthday, 'dd/MM/yyyy'),
      adresse: formData.adresse,
      tel: formData.tel,
      password: formData.password,
      added_date: this.datePipe.transform(this.myDate, 'dd/MM/yyyy'),
      job: formData.job,
      fax: formData.fax,
      gender: formData.gender,
      rpps: formData.rpps,
      role: "2",
      title: formData.title,
      consontement: formData.consontement,
    };
  }

  private preparePatientData(): any {
    const phoneWithCountryCode = this.prependCountryCode(
      this.registerFormPat.value.tel,
      this.selectedCountryCode
    );

 
  }

  private prependCountryCode(phone: string, countryCode: string): string {
    return `${countryCode}${phone}`;
  }

  private showSuccessMessage() {
    const successMessage =
      localStorage.getItem('langauage') === 'fr'
        ? 'Inscription réussie'
        : 'Successful registration';

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: successMessage,
      showConfirmButton: false,
      timer: 2000,
    });
  }

  private showErrorMessage() {
    const errorMessage =
      localStorage.getItem('langauage') === 'fr'
        ? 'Inscription non réussie'
        : 'Registration not successful';

    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  
  public onValChange2(val: any) {
    this.selectedVal2 = val;
/*     console.log(this.selectedVal2) */
    this.status = (this.action + this.selectedVal2)


  }
  onReset() {
    this.submitted = false;
    this.registerFormPro.reset();
    this.registerFormPat.reset();

  }
}
