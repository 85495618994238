import { Component, Inject, Injector, ComponentFactoryResolver, Type } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddPatientComponent } from '../../views/professionnel/add-patient/add-patient/add-patient.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {
  contentComponent: Type<any> = AddPatientComponent;
  contentInjector: Injector;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Create an injector with the data
    this.contentInjector = Injector.create({
      providers: [{ provide: 'dialogData', useValue: data }],
      parent: this.injector
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
