// password-dialog.component.ts
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-dialog',
  template: `
    <h2 mat-dialog-title>Password Confirmation</h2>
    <div mat-dialog-content>
      <mat-form-field>
        <input matInput [(ngModel)]="password" type="password" placeholder="Enter your password" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button (click)="confirm()">Confirm</button>
    </div>
  `,
})
export class PasswordDialogComponent {
  password: string = '';

  constructor(private dialogRef: MatDialogRef<PasswordDialogComponent>) {}

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(this.password);
  }
}
