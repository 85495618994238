<!-- ======= Top Bar ======= -->
<div id="topbar" class="d-flex align-items-center fixed-top">
  <div class="container d-flex justify-content-between">
    <!-- Contact Information Section (Empty for now) -->
    <div class="contact-info d-flex align-items-center"></div>

    <!-- Language Selector Component -->
    <div class="container d-flex justify-content-end align-items-center">
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="languageSelect" (ngModelChange)="onLangChange($event)">
          <mat-select-trigger>
            <div class="language-option d-flex align-items-center">
              <img [src]="getFlagIcon(languageSelect)" alt="Flag" class="flag-icon" />
              <span>{{ getLanguageLabel(languageSelect) }}</span>
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let lang of languages" [value]="lang.value">
            <div class="language-option d-flex align-items-center">
              <img [src]="lang.flagIcon" alt="Flag" class="flag-icon" />
              <span>{{ lang.label }}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Social Media Links -->
      <div class="d-lg-flex social-links align-items-center">
        <a href="https://www.facebook.com/profile.php?id=100063058550130" class="facebook">
          <i class="fab fa-facebook-f fa-lg"></i>
        </a>
        <a href="https://www.instagram.com/stimed_france/" class="instagram">
          <i class="fab fa-instagram fa-lg"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
  <div class="container d-flex align-items-center justify-content-between">
    <!-- Logo Section -->
    <div class="logo d-flex align-items-center">
      <img src="../../../assets/img/logo.png" style="height: 70px; width: 70px; padding-right: 10px;" alt="Logo" />
      <h1 class="logo-text me-auto"><a>Scores</a></h1>
    </div>

    <!-- Navigation Menu for Desktop -->
    <nav class="navbar d-none d-lg-flex">
      <ul class="d-flex align-items-center">
        <li><a class="nav-link" routerLink="/">{{ 'HOMES.HOME' | translate }}</a></li>
        <li><a class="nav-link" routerLink="about" routerLinkActive="active">{{ 'HOMES.APROP' | translate }}</a></li>
        <li><a class="nav-link" routerLink="contact" routerLinkActive="active">{{ 'HOMES.CONTACT' | translate }}</a></li>
        <li>
          <ng-container *ngIf="!status; else Unlog">
            <button mat-raised-button color="primary" (click)="openDialog()">
              <mat-icon>login</mat-icon>
              {{ 'AUTH.IDENTIFIER' | translate }}
            </button>
          </ng-container>
        </li>
      </ul>
    </nav>

    <!-- Mobile Navigation Menu for Small Screens -->
    <div class="mobile-menu d-lg-none">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!status; else UnlogMobile">
          <button mat-menu-item (click)="openDialog()">
            <mat-icon>login</mat-icon>
            {{ 'AUTH.IDENTIFIER' | translate }}
          </button>
        </ng-container>
        <a mat-menu-item routerLink="/" routerLinkActive="active">{{ 'HOMES.HOME' | translate }}</a>
        <a mat-menu-item routerLink="about" routerLinkActive="active">{{ 'HOMES.APROP' | translate }}</a>
        <a mat-menu-item routerLink="contact" routerLinkActive="active">{{ 'HOMES.CONTACT' | translate }}</a>
      </mat-menu>
    </div>
  </div>

  <!-- Conditional Template for Logged-In User (Desktop and Mobile) -->
  <ng-template #Unlog>
    <div class="login-button d-none d-lg-block" *ngIf="whois; else PtientUser">
      <a routerLink="./professionnel/profil" class="appointment-btn scrollto" style="font-weight: bold; color: white;">
        {{ 'HOMES.ESPACEPRO' | translate }}
      </a>
    </div>
    <ng-template #PtientUser>
      <a routerLink="./patient/profil" class="appointment-btn scrollto" style="font-weight: bold; color: white;">
        {{ 'HOMES.ESPACEPATIENT' | translate }}
      </a>
    </ng-template>
  </ng-template>

  <!-- Template for Mobile Logged-In User -->
  <ng-template #UnlogMobile>
    <div *ngIf="whois; else PtientUserMobile">
      <a mat-menu-item routerLink="./professionnel/profil" class="appointment-btn scrollto">
        {{ 'HOMES.ESPACEPRO' | translate }}
      </a>
    </div>
    <ng-template #PtientUserMobile>
      <a mat-menu-item routerLink="./patient/profil" class="appointment-btn scrollto">
        {{ 'HOMES.ESPACEPATIENT' | translate }}
      </a>
    </ng-template>
  </ng-template>
</header>

<!-- Main Content -->
<main id="main">
  <router-outlet></router-outlet>
</main>
