// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCVa6JkRbJbXWDMCQAQUsv-lvoZyxxVOek",
  authDomain: "scores-31a9b.firebaseapp.com",
  databaseURL: "https://scores-31a9b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scores-31a9b",
  storageBucket: "scores-31a9b.appspot.com",
  messagingSenderId: "24416512067",
  appId: "1:24416512067:web:c84f4acb959a3f8ad80cf6",
  measurementId: "G-XM0LJZCXVR"
};

// Initialize Firebase
