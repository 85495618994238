import { Country } from '../interfaces/country.interface';
 
export const COUNTRIES: Country[] = [
    { code: '+1', name: 'United States' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+33', name: 'France' },
    { code: '+91', name: 'India' },
    { code: '+49', name: 'Germany' },
    { code: '+81', name: 'Japan' },
    { code: '+86', name: 'China' },
    { code: '+61', name: 'Australia' },
    { code: '+55', name: 'Brazil' },
    { code: '+34', name: 'Spain' },
    { code: '+39', name: 'Italy' },
    { code: '+7', name: 'Russia' },
    { code: '+27', name: 'South Africa' },
    { code: '+82', name: 'South Korea' },
    { code: '+60', name: 'Malaysia' },
    { code: '+62', name: 'Indonesia' },
    { code: '+52', name: 'Mexico' },
    { code: '+41', name: 'Switzerland' },
    { code: '+46', name: 'Sweden' },
    { code: '+31', name: 'Netherlands' },
    { code: '+48', name: 'Poland' },
    { code: '+98', name: 'Iran' },
    { code: '+90', name: 'Turkey' },
    { code: '+30', name: 'Greece' },
    { code: '+43', name: 'Austria' },
    { code: '+64', name: 'New Zealand' },
    { code: '+21', name: 'Saudi Arabia' },
    { code: '+20', name: 'Egypt' },
    { code: '+356', name: 'Malta' },
    { code: '+63', name: 'Philippines' },
    { code: '+994', name: 'Azerbaijan' },
    { code: '+380', name: 'Ukraine' },
    { code: '+964', name: 'Iraq' },
    { code: '+965', name: 'Kuwait' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+971', name: 'United Arab Emirates' },
    { code: '+973', name: 'Bahrain' },
    { code: '+974', name: 'Qatar' },
    { code: '+975', name: 'Bhutan' },
    { code: '+962', name: 'Jordan' },
    { code: '+967', name: 'Yemen' },
    { code: '+249', name: 'Sudan' },
    { code: '+218', name: 'Libya' },
    { code: '+970', name: 'Palestine' },
    { code: '+291', name: 'Eritrea' },
    { code: '+253', name: 'Djibouti' },
    { code: '+212', name: 'Morocco' },
    { code: '+213', name: 'Algeria' },
    { code: '+216', name: 'Tunisia' }
    // Add more countries as needed
  ];
  