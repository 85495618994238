import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Patient } from 'src/app/views/interfaces/patient.interface';
import { AuthPatientService } from 'src/app/views/services/patient/auth-patient.service';
import{UpdProfilPatientService} from '../../views/services/patient/upd-profil-patient.service'
import { LoaderService } from 'src/app/views/services/loader/loader.service';
import { TranslationService } from 'src/app/views/services/translation/translation.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-patient-layout',
  templateUrl: './patient-layout.component.html',
  styleUrls: ['./patient-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      // Fade in when entering
      transition(':enter', [
        style({ opacity: 0 }), // Initial state
        animate('300ms ease-in', style({ opacity: 1 })) // Fading in
      ]),
      // Fade out when leaving
      transition(':leave', [
        style({ opacity: 1 }), // Initial state
        animate('300ms ease-out', style({ opacity: 0 })) // Fading out
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PatientLayoutComponent implements OnInit {
fullname:Patient
id:any
languageSelect = 'fr'; // Set the initial selected language
isOpen = true; // Initially open
patient:Patient
dataPatient={
  name:'',
  lastname:'',
  email:'',
  birthday:0,
  tel:0,
  id:'',
  adresse:'',
  ssn:'',
  gender:'',
  photo:'',
  archived:'',
  account_state:'',
  added_date:'',

}
readonly languages = [
  { value: 'en', label: 'English', flagIcon: '../../../assets/flags/eng.png' },
  { value: 'fr', label: 'Français', flagIcon: '../../../assets/flags/fr.png' },
];
  constructor( public loaderService: LoaderService, private  translationService:TranslationService,public  updateservice:UpdProfilPatientService,private router:Router,private  authPat:AuthPatientService,) {

    this.id= localStorage.getItem('id')
    this.patient=this.authPat.getUsername()
   
    this.updateservice.getPatient(this.id).subscribe((response=>{
     this.dataPatient.name=response.name;
     this.dataPatient.lastname=response.lastname;
     this.dataPatient.email=response.email;
     this.dataPatient.photo=response.photo;
     
      
      this.dataPatient = response}))
      
  }

  ngOnInit(): void {
    this.fullname=this.authPat.getUsername()
    const storedLanguage = localStorage.getItem('langauage');

    // Set the initial value for languageSelect based on local storage
    this.languageSelect = storedLanguage || 'fr'; // Use 'fr' as the default language if not found in local storage
  }

  logout(){
    localStorage.removeItem('token_Pat')
    this.router.navigate(['/'])
  }

 // Helper method to get the flag icon based on the selected language
 getFlagIcon(language: string): string {
  const selectedLang = this.languages.find((lang) => lang.value === language);
  return selectedLang ? selectedLang.flagIcon : '';
}

// Helper method to get the language label based on the selected language
getLanguageLabel(language: string): string {
  const selectedLang = this.languages.find((lang) => lang.value === language);
  return selectedLang ? selectedLang.label : '';
}
onLangChange(currentLang: string) {
  localStorage.setItem('langauage', currentLang);
  this.translationService.useLang(currentLang);
}

}
