import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.snackBar.open(message, '×', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'success',
    });
  }

  showError(message: string): void {
    this.snackBar.open(message, '×', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: 'error',
    });
  }
}
