import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  constructor(public loaderService: LoaderService) { }
  

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);

    const tokenPat = localStorage.getItem('token_Pat');
    const tokenPro = localStorage.getItem('token_Pro');
    const token = tokenPat || tokenPro;

    if (token) {
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    } else {
        // Add your logic here for handling the absence of tokens
    }

    return next.handle(req).pipe(
        finalize(() => {
            this.loaderService.isLoading.next(false);
        })
    );
}
}