<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container-fluid">
      <!-- 404 Error Text -->
      <div class="text-center">
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="error mx-auto" data-text="404">404</div>
        <p class="lead text-gray-800 mb-5">Page Not Found</p>
        <p class="text-gray-500 mb-0">
          It looks like you found a glitch in the matrix...
        </p>
        <a routerLink="/">&larr; Back to home</a>
      </div>
    </div>
    <!-- /.container-fluid -->
  </body>
</html>
