import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.css']
})
export class EditableFieldComponent {
  @Input() control: FormControl;
  @Input() fieldName: string;
  @Input() editable: boolean = true; // Default value true for editing mode
  @Output() edit = new EventEmitter<string>();
  @Output() save = new EventEmitter<string>();
  editing = false;
  // Les données à afficher dans le select
  title = [
    { value: 'professionnel', viewValue: 'Pr' },
    { value: 'doctor', viewValue: 'Dr ' },
    { value: 'mester', viewValue: 'Mr' },
    { value: 'miss', viewValue: 'Mme ' },
  ];
  startEditing() {
    if (this.editable) {
      this.edit.emit(this.fieldName);
      this.editing = true;
    }
  }

  stopEditing() {
    if (this.editable) {
      this.save.emit(this.fieldName);
      this.editing = false;
    }
  }
}
