import { Component, OnInit } from '@angular/core';
/**
 * @title spinner Resusable Component
 * @description component to show spinner when data is loading
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
