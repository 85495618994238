import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureComponent } from './signature/signature.component';
import { MatIconModule} from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav'; // Import MatSidenavModule
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { NoDataComponent } from './no-data/no-data.component';
import { MatDialogComponent } from './mat-dialog/mat-dialog.component';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { EditableFieldComponent } from './editable-field/editable-field.component';
import { PasswordInputComponent } from './password-input/password-input.component';

@NgModule({
  declarations: [SpinnerComponent, SignatureComponent, CustomButtonComponent, NoDataComponent, MatDialogComponent, PasswordDialogComponent, EditableFieldComponent, PasswordInputComponent],
  imports: [
    CommonModule,TranslateModule,
    MatFormFieldModule,MatSelectModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatDividerModule,
    MatSidenavModule,
    ReactiveFormsModule
  ] ,
   exports: [SpinnerComponent,EditableFieldComponent,CustomButtonComponent,NoDataComponent,MatDialogComponent,PasswordDialogComponent,PasswordInputComponent],

})
export class PartialsModule { }
