<div class="center-container">
    <mat-card>
        <h2>Reset Password</h2>
        <form [formGroup]="resetPasswordForm" >        
            <mat-form-field appearance="fill">
              <mat-label>Nouveau mot de passe</mat-label>
              <input
                matInput
                type="{{ showPassword ? 'text' : 'password' }}"
                formControlName="newPassword"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="togglePasswordVisibility('newPassword')"
              >
                <mat-icon>{{
                  showPassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error *ngIf="resetPasswordForm.get('newPassword').hasError('required')">
                Le nouveau mot de passe est requis
              </mat-error>
              <mat-error *ngIf="resetPasswordForm.get('newPassword').hasError('minlength')">
                Le mot de passe doit contenir au moins 8 caractères
              </mat-error>
              <mat-error *ngIf="resetPasswordForm.get('newPassword').hasError('pattern')">
                Le mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial (@$!%*?&)
              </mat-error>
            </mat-form-field>
          
            <mat-form-field appearance="fill">
              <mat-label>Confirmation du mot de passe</mat-label>
              <input
                matInput
                type="{{ showPassword ? 'text' : 'password' }}"
                formControlName="confirmPassword"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="togglePasswordVisibility('confirmPassword')"
              >
                <mat-icon>{{
                  showPassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error *ngIf="resetPasswordForm.get('confirmPassword').hasError('required')">
                La confirmation du mot de passe est requise
              </mat-error>
              <mat-error 
              *ngIf="!resetPasswordForm.get('confirmPassword').hasError('required') && resetPasswordForm.hasError('passwordsNotMatch')">
                Les mots de passe ne correspondent pas
              </mat-error>
              
            </mat-form-field>
          
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="login-button"
              (click)="resetPassword()"
              [disabled]="resetPasswordForm.invalid || resetPasswordForm.get('newPassword').value !== resetPasswordForm.get('confirmPassword').value"
            >
              Réinitialiser le mot de passe
            </button>
        </form>
    </mat-card>
    
      
</div>