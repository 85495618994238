<div style="overflow-y: auto;">
  <div class="container">
    <div class="bilan">
      <div class="bilan-header">
        <h2>{{'PROPREVIEW.ReprotTile'|translate}}</h2>
      </div>
      <div class="bilan-body">
  
        <div class="info-sections">
            <img src="../../../../../assets/img/avtt.png" alt="Identity Card" class="identity-card-image">
          
          <div class="patient-info">
            <h3>{{'PROPREVIEW.SECTION1'|translate}}</h3>
            <div class="info-row">
              <span class="label">{{'AUTH.NOM'|translate}}:</span>
              <span>{{patientData?.name}} {{patientData?.lastname}}</span>
            </div>
            <div class="info-row">
              <span class="label">{{'AUTH.EMAIL'|translate}}:</span>
              <span>{{patientData?.email}}</span>
            </div>
            <div class="info-row">
              <span class="label">{{'AUTH.SIZE'|translate}}:</span>
              <span>{{patientData?.size}}</span>
            </div>
            <div class="info-row">
              <span class="label">{{'AUTH.POID'|translate}}:</span>
              <span>{{patientData?.weight}} </span>
            </div>
          
            <div class="info-row">
              <span class="label">{{'AUTH.GENDER'|translate}}:</span>
              <span>{{patientData?.gender}}</span>
            </div>
            <div class="info-row">
              <span class="label">Age:</span>
              <span>{{age}}</span>
            </div>
  
            <div class="info-row" *ngIf="BMIResult">
              <span class="label">IMC:</span>
              <span>{{BMIResult?.bmi?.toFixed(2)}} </span>
              <span>({{BMIResult?.interpretation}})</span>
            </div>
            <div class="imcImage" *ngIf="getPhotoByIMC()">
              <img [src]="getPhotoByIMC().url" alt="Interactive Photo"  style="mix-blend-mode: multiply;" />
            </div>
          </div>
          
         
  
          <div class="form-info">
            <h3>{{'PROPREVIEW.SECTION2'|translate}}</h3>
            <div class="summary-bilan">
              <div class="summary-bilan-info-row">
                <span class="label">{{'PROPREVIEW.tt'|translate}}:</span>
                <span>{{tabRep?.form_title}}</span>
              </div>
              <!-- <div class="summary-bilan-description">{{tabRep.form_description}}</div> -->
              <div class="summary-bilan-info">
                <div class="summary-bilan-info-row">
                  <span class="label">{{'PROPREVIEW.NQUES'|translate}}:</span>
                  <span>{{questionCount}}</span>
                </div>
  
                <div class="summary-bilan-info-row">
                  <span class="label">{{'AUTH.GENDER'|translate}}:</span>
                  <span>{{tabRep?.form_genre}}</span>
                </div>
                <div class="summary-bilan-title">Scores:</div>
                <!-- <div class="summary-bilan-description">{{tabRep.form_description}}</div> -->
                <div class="summary-bilan-info">
                  <div class="summary-bilan-info-row">
                    <span class="label"></span>
                  
                    <div class="scores-container">
                      <div *ngFor="let s of tabRep.score; let i = index" class="score-item">
                        <div style="font-size: medium;">score{{i + 1}}: {{s.toFixed(2)}}</div>
                      </div>
                    </div>
                    
                  </div>
                  
                 
                </div>
              </div>
              
            </div>
  
            <!-- <div class="summary-bilan">
              <div class="summary-bilan-title">Score:</div>
              <div class="summary-bilan-info">
                <div class="summary-bilan-info-row">
                  <span class="label"></span>
                  <div> <span class="summary-bilan-title">{{tabRep.score}}</span>
                  </div>
  
                </div>
               
              </div>
              
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <section class="accordion" style=" max-height: auto;">
    <input type="checkbox" name="collapse" id="handle1" checked="checked">
    <h2 class="handle">
      <label for="handle1">{{'PROPREVIEW.CONTENT'|translate}}</label>
    </h2>
    <div class="content" style="overflow-y: auto;" >
      <div *ngIf="tabRep">
        <div class="col-12 col-md-12 col-lg-12 mx-auto">
          <div class="card">
                <div *ngIf="tabRep" class="card-header">
                 <h1 class="form-title">{{tabRep?.form_title}}</h1>
              </div>
        
          </div>
        </div>
        
        <ul class="list">
          <div  style="height: 1500px" itemSize="90" >
            <div class="col-12 col-md-12 col-lg-12 mx-auto">
              <div class="card" *ngIf="tabRep.form_description" >
                    <div *ngIf="tabRep" class="card-header">
                     <h1 class="form-title">{{ 'AFFECTFORMS.DESC' | translate }}</h1>
                    <p class="form-description">{{tabRep.form_description}}</p>
          
                 
                  </div>
         
              </div>
          </div>
            <div *ngFor="let response of tabRep.responses;let s = index">
        
              <div class="col-12 col-md-12 col-xs-12 col-lg-12 mx-auto">
                <div class="card-section" style="background-color: #ffffffde;"  *ngIf="response.title!=''">
                      <div class="card-header">
                       
                        <div class="card question-card">
            
                
                            <div   class="row card-body col-12">
            
                            <div class="row w-100">
                                
                                <div class="col-lg-12 ">
                                  <h2 class="question-title">
                                    <p class="hint">{{s+1}}- {{response.title}} </p>
                                  </h2>  
                                </div>
            
                               
                            
                            </div>
            
            
                            <div class="row w-100 mt-1">
                                <div *ngIf="response?.type == 'Nomber de jours'" class="col-lg-8 col-sm-12" >
                                    <!-- => show simple input -->
                            
                                    <!-- <p class="hint"> {{section.title}}</p> -->
                                    <p class="hint"> {{response.minRange}} </p> 
                              
                                  </div>
                                <div *ngIf="response?.type == 'Text court'" class="col-lg-8 col-sm-12">
                                  <!-- <p class="hint"> {{section.title}}</p> -->
                                    <p class="hint"> {{response.textResponse}} </p> 
                                </div>
                            
                                
                                <div *ngIf="response?.type == 'Cases à cocher'" style="width: 100%;" class="col-lg-12 col-sm-12 w-100">
                                   <!--  <p class="hint"> {{section.title}} </p> -->
                                 
                                    <div style="width: 100%;" *ngFor="let op of response.options; let o = index;">
                                      <div class="row">
                                  
                                        <div class="col">
                                    
                                            <mat-radio-group 
                                            aria-labelledby="example-radio-group-label"
                                            class="example-radio-group"
                                            [(ngModel)]="favoriteSeason">
                                          <mat-radio-button class="m-2 w-100" style="width: 100%;" disabled [value]="op.text"  [checked]="op.selected">
                                        
                                            <p class="hint">{{op.text}} </p>
                                        </mat-radio-button>
                                        <img *ngIf="op.image !=''" style="width: 250px;" class="img-thumbnail" [src]="op.image" alt="">
                                    </mat-radio-group>
                                      </div>
                                  
                                   
                                 
                                    </div>
                                </div>
                            </div>
            
                             <div *ngIf="response?.type == 'Choix multiples'" style="width: 100%;" class="col-lg-12 col-sm-12 w-100">
                              <!--   <p class="hint"> {{section.title}} </p> -->
                                <div style="width: 100%;" *ngFor="let op of response.optioncm; let o = index;">
                                  <div class="row">
                                    <div class="col">
                                      <mat-checkbox class="m-2 w-100" style="width: 100%;" disabled [checked]= "op.selected">
                                        <p class="hint">{{op.text}}</p>
                                    </mat-checkbox>
                                    <img *ngIf="op.image !=''" style="width: 250px;" class="img-thumbnail" [src]="op.image" alt="">
            
                                   <!--  <div  *ngIf="section.questions[i].optioncm[o].image.length > 1 " style="width: 100%; margin-left: 44px;margin-top: -16px;">
                                        <img style="width: 250px;" class="img-thumbnail" [src]="section.questions[i].optioncm[o].image " alt="">
                                
                                    </div> -->
                                    </div>
                                    
                                   <!--  <div class="col-2">
                                      <input  [(ngModel)]="section.questions[i].optioncm[o].score"  class="question-score" value="0" disabled>
                                    </div>
                                    <div *ngIf="section.questions[i].textecourt" class="col-2">
                                      <div class="m-2 w-100">
                                        <input   class="form-control" value="" disabled>
                                      
                                      </div>
                                    </div> -->
                                  </div>
                                 
                                    <br>
                              
                                </div>
                              
                           
            
                            </div>
                            <div *ngIf="response?.type === 'Range Bar'" style="width: 100%;" class="col-lg-12 col-sm-12 w-100">
                              <div class="row m-2">
                                  <div class="col-lg-8 col-sm-12" style="padding-left: 3%;">
                                      <div class="simple-slider disabled-slider">
                                          <ng5-slider
                                              [value]="response?.scoreOptionRangeBar || 0"
                                              [options]="sliderMakeOptions(response?.scoreOptionRangeBarQuestion)"
                                          ></ng5-slider>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                          
                          
                          
                          
                          
        
                            <div *ngIf="response?.type == 'VISUELLE ANALOGIQUE'" style="width: 100%;" class="col-lg-12 col-sm-12 w-100">
                             <!--    <p class="hint"> {{section.title}} </p> -->
                              <div class="row  m-2">
                                <div class="col-lg-8 col-sm-12" style="padding-left: 3%;">
                                    <div class="simple-slider">
                
                                      <div class="row">
                                        <!-- <img style="width: 10%;height: 10%;" class="img-thumbnail" src="assets/img/placeholder.png" alt=""> -->
                                        <div class='container23' >
                                        
                                        <img  class="img-thumbnail" src="../../../../../assets/Douleurgraph1.png" alt="">
                                        <input type="range" min="0" max="10" [value]="response.score" class="slider" name='slider' id="slider" disabled>
                                        
                                        </div>
                                        <!-- <img style="width: 10%;height: 10%;" class="img-thumbnail" src="assets/img/placeholder.png" alt=""> -->
                                      </div>
                
                                    </div>
                
                
                                  </div>
            
        
                                </div> 
            
                           
                          
                        
            
            
                       
                            </div>
      
  
  
  
  
                          <!-- web & mobile  -->
                          <div *ngIf="response.type === 'Grille de cases à cocher 2'" style="width: 100%;" class="col-lg-12 col-sm-12 w-100">
                            <table class="table table-borderless">
                              <thead></thead>
                              <tbody>
                                <ng-container *ngFor="let question of response.scoreOptioncm2.questions; let m = index">
                                  <tr>
                                    <th scope="row">
                                      <h6 style="color: #212529;">{{m+1}}- {{ question.title }}</h6>
                                    </th>
                                    <td *ngFor="let checked of response.scoreOptioncm2.table[m]; let k = index">
                                      <mat-checkbox [checked]="checked" [disabled]="true">
                                        {{ response.scoreOptioncm2.grille[k].title }}
                                      </mat-checkbox>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                          
                          
  
  
  
                   
  
                          
  
                            </div>
                
            
            
                        </div>
                    </div>
           
                </div>
            </div>
          
           
        </div>
            </div>
          </div>
        </ul>
       
      </div>
        </div>
  </section>
  
</div>