<div class="container">
    <div class="main-body">
  
 
      <nav aria-label="breadcrumb" class="main-breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="index.html">Patient</a></li>
          <li class="breadcrumb-item"><a href="javascript:void(0)">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
      </nav>

  
      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="d-flex flex-column align-items-center text-center">
                <div class="profile-img"> <img style="height: 300px; width: 300px;"
                  [src]="Patient.photo"
                  alt="" />
                 

                </div>
                <div class="mt-3">
                  
                  <h4>{{Patient.name}} {{Patient.lastname}}</h4>
  
  
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Nom et prénom</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{Patient.name}} {{Patient.lastname}}
  
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Email</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{Patient.email}} </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Id</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{Patient._id}} </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Télèphone</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{Patient.tel}} </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Date de naissance</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.Patient.birthday}} </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Ssn</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.Patient.ssn}} </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Adresse</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.Patient.adresse}} </div>
              </div>
              <hr>
        
            </div>
          </div>
  
  
  
        </div>
      </div>
  
    </div>
  </div>
  