import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatientDataService {
  private PatientData = new BehaviorSubject<any>({});
  currentsize_PatientData = this.PatientData.asObservable();

  private messageSource=new BehaviorSubject<any>('ttt')
private IndexSource=new BehaviorSubject<any>('ttt')


currentMessage = this.messageSource.asObservable();
currentindex = this.IndexSource.asObservable();
  constructor() {


    


   }

   GetIndex(index:any){
    this.IndexSource.next(index)
    
  }
  GetId(PatID:any){
    this.messageSource.next(PatID)


  }

  // sendSize_weight(size,weight) {
  //   console.log(size,weight)
  //   let data={
  //     size:size,
  //     weight:weight
  //   }
  //   console.log(data.size ,data.weight)

  //   this.size_weight.next(data);
    
  // }

   GetPatientData(data) {

    this.PatientData.next(data);
    
  }
}
