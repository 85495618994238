import { Component, OnInit } from '@angular/core';
import { UpdProfilPatientService } from 'src/app/views/services/patient/upd-profil-patient.service';
import { FormDataService } from '../../services/shared-data/form-data.service';

@Component({
  selector: 'app-details-patient',
  templateUrl: './details-patient.component.html',
  styleUrls: ['./details-patient.component.css']
})
export class DetailsPatientComponent implements OnInit {
  PatientID:any
  Patient={

    name: '',
    lastname: '',
    birthday: '',
    adresse: '',
    tel: '',
    email: '',
    password: '',
    ssn: '',
    gender: '',
    photo: '',
    account_state: true,
    archived: false,
    added_date: '',
    _id:""
  
  }
  constructor( private updateservice:UpdProfilPatientService,private data:FormDataService,) {
    this.data.currentdetailsPatient.subscribe(PatientID=>this.PatientID =PatientID)

   }

  ngOnInit(): void {

    this.updateservice.getPatient(this.PatientID).subscribe(response=>
      this.Patient = response
      )
  }

}
