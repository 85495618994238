<mat-dialog-content>
  <div class="login-page">
    <mat-card class="mat-elevation-z12 responsive-card">
      <mat-tab-group mat-stretch-tabs class="example-stretched-tabs">
        <mat-tab label="Modifier profil">
          <mat-card-content>
            <form [formGroup]="updateForm" (ngSubmit)="onUpdate()">
              <!-- Name -->
              <div class="form-group">
                <label for="name">Name</label>
                <input 
                  type="text" 
                  id="name" 
                  class="form-control" 
                  placeholder="Name" 
                  formControlName="name">
                <div class="alert alert-danger" *ngIf="updateForm.get('name').invalid && (updateForm.get('name').touched || updateForm.get('name').dirty)">
                  <div *ngIf="updateForm.get('name').errors?.required">Name is required.</div>
                  <div *ngIf="updateForm.get('name').errors?.minlength">Name must be at least 2 characters.</div>
                </div>
              </div>

              <!-- Lastname -->
              <div class="form-group">
                <label for="lastname">Lastname</label>
                <input 
                  type="text" 
                  id="lastname" 
                  class="form-control" 
                  placeholder="Lastname" 
                  formControlName="lastname">
                <div class="alert alert-danger" *ngIf="updateForm.get('lastname').invalid && (updateForm.get('lastname').touched || updateForm.get('lastname').dirty)">
                  <div *ngIf="updateForm.get('lastname').errors?.required">Lastname is required.</div>
                  <div *ngIf="updateForm.get('lastname').errors?.minlength">Lastname must be at least 2 characters.</div>
                </div>
              </div>

              <!-- Email -->
              <div class="form-group">
                <label for="email">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  class="form-control" 
                  placeholder="Email" 
                  formControlName="email">
                <div class="alert alert-danger" *ngIf="updateForm.get('email').invalid && (updateForm.get('email').touched || updateForm.get('email').dirty)">
                  <div *ngIf="updateForm.get('email').errors?.required">Email is required.</div>
                  <div *ngIf="updateForm.get('email').errors?.email">Invalid email format.</div>
                </div>
              </div>

              <!-- Adeli -->
              <div class="form-group">
                <label for="adeli">Adeli</label>
                <input 
                  type="text" 
                  id="adeli" 
                  class="form-control" 
                  placeholder="Adeli" 
                  formControlName="adeli">
                <div class="alert alert-danger" *ngIf="updateForm.get('adeli').invalid && (updateForm.get('adeli').touched || updateForm.get('adeli').dirty)">
                  <div *ngIf="updateForm.get('adeli').errors?.required">Adeli is required.</div>
                </div>
              </div>

              <!-- Rpps -->
              <div class="form-group">
                <label for="rpps">Rpps</label>
                <input 
                  type="text" 
                  id="rpps" 
                  class="form-control" 
                  placeholder="Rpps" 
                  formControlName="rpps">
                <div class="alert alert-danger" *ngIf="updateForm.get('rpps').invalid && (updateForm.get('rpps').touched || updateForm.get('rpps').dirty)">
                  <div *ngIf="updateForm.get('rpps').errors?.required">Rpps is required.</div>
                </div>
              </div>

              <!-- Fax -->
              <div class="form-group">
                <label for="fax">Fax</label>
                <input 
                  type="text" 
                  id="fax" 
                  class="form-control" 
                  placeholder="Fax" 
                  formControlName="fax">
                <div class="alert alert-danger" *ngIf="updateForm.get('fax').invalid && (updateForm.get('fax').touched || updateForm.get('fax').dirty)">
                  <div *ngIf="updateForm.get('fax').errors?.required">Fax is required.</div>
                </div>
              </div>

              <!-- Tel -->
              <div class="form-group">
                <label for="tel">Tel</label>
                <input 
                  type="text" 
                  id="tel" 
                  class="form-control" 
                  placeholder="Tel" 
                  formControlName="tel">
                <div class="alert alert-danger" *ngIf="updateForm.get('tel').invalid && (updateForm.get('tel').touched || updateForm.get('tel').dirty)">
                  <div *ngIf="updateForm.get('tel').errors?.required">Tel is required.</div>
                </div>
              </div>

              <!-- Address -->
              <div class="form-group">
                <label for="adresse">Address</label>
                <input 
                  type="text" 
                  id="adresse" 
                  class="form-control" 
                  placeholder="Address" 
                  formControlName="adresse">
                <div class="alert alert-danger" *ngIf="updateForm.get('adresse').invalid && (updateForm.get('adresse').touched || updateForm.get('adresse').dirty)">
                  <div *ngIf="updateForm.get('adresse').errors?.required">Address is required.</div>
                </div>
              </div>

              <!-- Success Message -->
              <div class="alert alert-success" *ngIf="messageSuccess">
                {{ messageSuccess }}
              </div>

              <!-- Submit Button -->
              <div class="btn-container">
                <button 
                  class="btn btn-primary" 
                  type="submit" 
                  [disabled]="updateForm.invalid">
                  Update Profile
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</mat-dialog-content>
