import { Component, OnInit } from '@angular/core';
import { AuthPatientService } from 'src/app/views/services/patient/auth-patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2'
import { DemandePatService } from 'src/app/views/services/patient/demande-pat.service';
import { FormGroup, FormControl, Validators,FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import {pastDateValidator} from '../../../helper/pastDateValidator'
import {MustMatch} from '../../../helper/MustMatch'
// Custom validator function for SSN length
export function telFaxLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const tel = control.value;
    if (tel && (tel.toString().length > 12 || tel.toString().length < 8)) {
      return { telLength: true }; // Validation failed
    }
    return null; // Validation passed
  };
}


// Custom validator function for SSN length
export function sizePoidLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const size = control.value;
    if (size && size.toString().length > 3) {
      return { sizeLength: true }; // Validation failed
    }
    return null; // Validation passed
  };
}
// Custom validator function for SSN length
export function ssnLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const ssn = control.value;
    if (ssn && ssn.toString().length !== 9) {
      return { ssnLength: true }; // Validation failed
    }
    return null; // Validation passed
  };
}
@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})
export class AddPatientComponent implements OnInit {
  registerFormPat: FormGroup;
  result:any;
  dataAddInvit:any;
  emailValid:string="";
  validSpinner=false;
  resultAdd=false;
  testName = false;
  testLastName = false;
  testEmail = false;
  testPassword = false;
  testAdresse = false;
  testGender = false;
  testBirthday = false;
  testTelephone = false;
  testSsn = false;
  url :any;
  patient = {

    name: '',
    lastname: '',
    email: '',
    birthday: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
    ssn: '',
    adresse: '',
    tel: '',
    password: '',
    added_date: '',
    gender: '',
    account_state: false,
    archived: false,
    photo:'',
    weight:0,
    size:0,
    mailConfirmation:true,
    consontement:true


  }
  hidePassword = true; // Initially, the password is hidden
  hide: boolean = true;

  registerForm: FormGroup;
  submitted = false;
  title = 'email-validation-tutorial';
  userEmails = new FormGroup({
    primaryEmail: new FormControl('',[
    Validators.required,
    
  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    secondaryEmail: new FormControl('',[
    Validators.required,
    
  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
  });  
  constructor(public datepipe: DatePipe, private formBuilder: FormBuilder,  private AuthPatient: AuthPatientService,private snackBar:MatSnackBar, private DemandeService: DemandePatService,private router: Router) { }
 
  ngOnInit(): void {
  
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    this.registerFormPat = this.formBuilder.group({

      name: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      birthday: ['', [Validators.required,pastDateValidator()]],
      tel: ['', [
        Validators.required,
        telFaxLengthValidator(),
        Validators.pattern(/^\d+$/) // Allow any number of digits
      ]],
      adresse: ['',[Validators.required, Validators.minLength(2)]],
      ssn: ['', [Validators.required, ssnLengthValidator()],Validators.pattern(/^\d{1,3}$/)],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
      ]],  
   confirmPassword: ['', [Validators.required,this.checkPassword ]],
      consontement: [true, Validators.requiredTrue],
      mailConfirmation: [true,],
      weight: ['', [ Validators.required,
        sizePoidLengthValidator(),
        Validators.pattern(/^\d+$/)]],
      size: ['', [ Validators.required,
        sizePoidLengthValidator(),
        Validators.pattern(/^\d+$/)]],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });


  }
  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
  checkPassword(control) {
    let enteredPassword = control.value
    let passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (!passwordCheck.test(enteredPassword) && enteredPassword) ? { 'requirements': true } : null;
  }
  checkInUseEmail(control) {
    // mimic http database access
    let db = ['tony@gmail.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 2000)
    })
  }
  get fPat() { return this.registerFormPat.controls; }

  // add patient 
  createPatien(){
    let formValue = this.registerFormPat.value;
    formValue.birthday = this.datepipe.transform(formValue.birthday, 'dd/MM/yyyy');
    
  this.AuthPatient.registerPatient(this.registerFormPat.value).subscribe((response) => {
/* console.log(response); */
this.dataAddInvit=response
 let obj ={
 
  doctor :localStorage.getItem('id_Pro'),
  patient:this.dataAddInvit._id,
  email:this.dataAddInvit.email,
  dataPatient:this.registerFormPat.value

}
/* console.log(obj) */
this.DemandeService.AddCreatePatient(obj).subscribe((res)=>{
   if(res){
    this.router.navigate(['/professionnel/contact'])
    location.reload();
   }

  }) 
})  
  }
  validEmail() {
    this.validSpinner = true;
  
    const obj = {
      email: this.fPat.email.value
  };


  
    this.AuthPatient.emailIsExist(obj).subscribe(
      (res) => {
        this.result = res;
        this.handleEmailValidationResult();
        console.log(res)
      },
      (error) => {
        this.validSpinner = false;
      }
    );
  }
    // This method will be triggered on the blur event
    formatInput(field: string) {
      const fieldValue = this.registerFormPat.get(field).value;
      this.registerFormPat.get(field).setValue(fieldValue.trim());
    }
  private handleEmailValidationResult() {
    setTimeout(async () => {
      this.validSpinner = false;
      if (this.result.res != "Invalid Email") {

        this.showToast("error", "Email exist déja");
      } else {
        this.showToast("success", "Account has been created");
        this.createPatien();
      }
    }, 2000);
    this.registerForm.reset(); 
  }
  
  private showToast(icon: "success" | "error", title: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      }
    });
  
    Toast.fire({
      icon: icon,
      title: title
    });
  }
  
  get f() { return this.registerFormPat.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerFormPat.invalid) {
            return;
        } else{
          this.validEmail()}
        

        // display form values on success
        /* alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerFormPat.value, null, 4)); */
    }

    onReset() {
        this.submitted = false;
        this.registerFormPat.reset();
    }

    myFunction() {
      this.hide = !this.hide;
    }
    onStrengthChanged(strength: number) {
      // You can leave it empty if you don't need to use the strength value.
    }
  
}
