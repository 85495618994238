import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css'],
})
export class CustomButtonComponent {
  @Input() dataToggle: string | null = null;
  @Input() dataTarget: string | null = null;
  @Input() buttonClass: string = '';
  @Input() iconClass: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
