import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading = false;
  private minimumDisplayTime = 1000; // Set to 1000 milliseconds (1 second)
  private startTime: number;

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  startLoading() {
    this.loading = true;
    this.startTime = Date.now(); // Record the start time
    this.isLoading.next(true);
  }

  stopLoading() {
    const elapsedTime = Date.now() - this.startTime;
    if (elapsedTime >= this.minimumDisplayTime) {
      // Minimum display time has passed, hide the spinner immediately
      this.loading = false;
      this.isLoading.next(false);
    } else {
      // Minimum display time hasn't passed, delay hiding the spinner
      setTimeout(() => {
        // Ensure the loading state is still true before hiding
        if (this.loading) {
          this.loading = false;
          this.isLoading.next(false);
        }
      }, this.minimumDisplayTime - elapsedTime);
    }
  }
}
