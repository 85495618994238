import { Component, OnInit, Input } from '@angular/core';

/**
 * @title No-data Resusable Component
 * @description component to show message in case there is no provided data from the backend
 */
@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit {
  /**
   * Message to be displayed when no data is found
   * @exemple 'No data available'
   */
@Input() message : string;
  constructor() { }

  ngOnInit(): void {
  }

}
