  <!-- Page Wrapper -->
  <div id="wrapper">

    <mat-sidenav *ngIf="isOpen" [@fadeInOut] class="sidebar" mode="side" opened>
        <!-- Sidebar content -->
        <mat-nav-list>
          <!-- Sidebar - Brand -->
          <a mat-list-item class="sidebar-brand"  routerLink="/">
            <div class="sidebar-brand-icon" >
            </div>
            <div class="sidebar-brand-text">{{ 'HOMES.ESPACEPRO' | translate }} </div>
          </a>
    <br>
          <!-- Divider -->
          <mat-divider></mat-divider>
    
          <!-- Nav Item - Pages Collapse Menu -->
          <a mat-list-item class="nav-link" routerLink="profil">
            <i class="fa-solid fa-address-card fa-lg"></i>            <span>{{ 'SIDEMENU.MC' | translate }}</span>
          </a>
    
          <!-- Nav Item - Utilities Collapse Menu -->
          <a mat-list-item class="nav-link" routerLink="Invitations">
            <i class="fa-solid fa-user-plus"></i>
                     <span class="align-middle">{{ 'SIDEMENU.AP' | translate }}</span>
          </a>
    
          <a mat-list-item class="nav-link" routerLink="contact">
            <i class="align-middle mr-2 fa fa-user-injured"></i>
            <span>{{ 'SIDEMENU.LP' | translate }}</span>
          </a>
    
          <a mat-list-item class="nav-link" routerLink="formulaires">
            <i class="fa-solid fa-file-medical fa-lg"></i>   <span>{{ 'SIDEMENU.MF' | translate }}</span>
          </a>
    
          <a mat-list-item class="nav-link" routerLink="liste-dossiers">
            <i class="fa-solid fa-folder fa-lg"></i>
            <span>{{ 'SIDEMENU.DO' | translate }}</span>
          </a>

          <!-- Divider -->
          <mat-divider></mat-divider>
      

          <a mat-list-item class="nav-link" data-toggle="modal" data-target="#logoutModal" href="">
            <i class="fa-solid fa-right-from-bracket fa-lg"></i>
               <span>  {{ 'SIDEMENU.DEC' | translate }}</span>
          </a>

        </mat-nav-list>
        
      </mat-sidenav>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div  id="content-wrapper" class="d-flex flex-column">

        <!-- Main Content -->
        <div id="content">

            <!-- Topbar -->
            <div class="loader-container"  *ngIf="loaderService.isLoading | async">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
            <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
         
                <!-- Sidebar Toggle (Topbar) -->
                <div class="menu-trigger" (click)="isOpen = !isOpen">
                    <div class="circle">
                      <i *ngIf="!isOpen" class="fas fa-chevron-right fa-1x" ></i>
                      <i *ngIf="isOpen" class="fas fa-chevron-left fa-1x"></i>
                    </div>
                  </div>
                  
        
                <!-- Topbar Navbar -->
                <ul class="navbar-nav ml-auto">
          
                    <!-- Nav Item - Search Dropdown (Visible Only XS) -->
             
                    <li class="nav-item dropdown no-arrow d-sm-none">
                        <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-search fa-fw"></i>
                        </a>
                        <!-- Dropdown - Messages -->
                        <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                            aria-labelledby="searchDropdown">
                            <form class="form-inline mr-auto w-100 navbar-search">
                                <div class="input-group">
                                    <input type="text" class="form-control bg-light border-0 small"
                                        placeholder="Search for..." aria-label="Search"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button">
                                            <i class="fas fa-search fa-sm"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>

                    <!-- Nav Item - Alerts -->
              
                   <!-- Language Selection Dropdown -->
<li class="nav-item dropdown no-arrow">
    <mat-form-field appearance="fill">
      <mat-select [(ngModel)]="languageSelect" (ngModelChange)="onLangChange($event)">
        <mat-select-trigger>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img [src]="getFlagIcon(languageSelect)" alt="Flag" class="flag-icon" />
            <span>{{ getLanguageLabel(languageSelect) }}</span>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let lang of languages" [value]="lang.value">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <img [src]="lang.flagIcon" alt="Flag" class="flag-icon" />
            <span>{{ lang.label }}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </li>
                    <div class="topbar-divider d-none d-sm-block"></div>

                    <!-- Nav Item - User Information -->
                    <li class="nav-item dropdown no-arrow">
                        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="mr-2 d-none d-lg-inline text-gray-600 small" style="font-weight: bold;font-size: 90%;">{{dataProf.name}} {{dataProf.lastname}}</span>
                            <!-- <div class="rotation">
                              <img *ngIf="dataProf.photo!=''" class="img-profile rounded-circle"
                           
                              [src]="dataProf.photo">
                              <img *ngIf="dataProf.photo==''" class="img-profile rounded-circle rotation2" src="../../../assets/img/avtt.png">

                            </div> -->
                                <!-- <div class="profile">
                                  <img *ngIf="dataProf.photo!=''" class="img-profile rounded-circle"
                           
                                  src="../../../assets/img/avtt.png">
                              <span *ngIf="isUserOnline; else offlineStatus" class="status online"></span>
                              <ng-template #offlineStatus>

                              <span   class="status offline"></span>
                            </ng-template> -->
                            <!-- <div>
                              <p *ngIf="isOnline; else offline">You are currently online.</p>
                              <ng-template #offline>
                                <p>You are currently offline. Please check your network connection.</p>
                              </ng-template>
                            </div> -->
                            
                            <!-- </div> -->
                     

                      <div class="profile">
                              <img *ngIf="dataProf.photo!=''" class="img-profile rounded-circle" src="../../../assets/img/avtt.png">
                              <span class="status online"></span>
              
                            </div>
              
              
                       
                          
                        </a>
                        
                        <!-- Dropdown - User Information -->
                        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown" style="align-items: center;">
                            
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item"  data-toggle="modal" data-target="#logoutModal">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" style="font-weight: bold;font-size: 90%;cursor: pointer;"></i>
                                {{ 'SIDEMENU.DEC' | translate }}
                            </a>
                        </div>

                    <!-- Modal -->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"MODALLOGOUT.title" |translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{"MODALLOGOUT.msg" |translate}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" >{{"MODALLOGOUT.cancel" |translate}}</button>
        <button type="button" (click)="logout()" class="btn btn-primary"  data-dismiss="modal">{{"MODALLOGOUT.confirm" |translate}}</button>
      </div>
    </div>
  </div>
</div>


                        
                    </li>

                </ul>
             
            </nav>
          
            <!-- End of Topbar -->
            <main id="main">
              <router-outlet></router-outlet>


            </main>