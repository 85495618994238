import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Patient } from '../../interfaces/patient.interface';
import { AuthPatientService } from '../../services/patient/auth-patient.service'
import { UpdProfilPatientService } from '../../services/patient/upd-profil-patient.service'
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';


/**
 * @title Edit Profile Patient Component
 * @description Component for editing patient profile information
 */
@Component({
  selector: 'app-edit-profil',
  templateUrl: './edit-profil.component.html',
  styleUrls: ['./edit-profil.component.css']
})
export class EditProfilComponent implements OnInit, OnDestroy {
  /** Properties */
  id: any;
  patient: Patient;
  dataArray: any = [];
  obsGet: Subscription;
  obsUpd: Subscription

    /** Data object for patient information */

  dataPatient = {
    name: '',
    lastname: '',
    email: '',
    birthday: 0,
    tel: 0,
    id: '',
    adresse: '',
    ssn: '',
    gender: '',
    photo: '',
    archived: '',
    account_state: '',
    added_date: '',

  }
  messageSuccess = ''
 /**
   * Constructor
   * @param snackBar Material snack bar
   * @param updateservice Patient profile update service
   * @param dialogRef Material dialog reference
   * @param data Dialog data injected
   * @param authPat Patient authentication service
   */
  constructor( private snackBar:MatSnackBar,public updateservice: UpdProfilPatientService, 
    public dialogRef: MatDialogRef<EditProfilComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogModel, public authPat: AuthPatientService) {
    this.id = this.authPat.geid()
    this.patient = this.authPat.getUsername()

    this.obsGet = this.updateservice.getPatient(this.id).subscribe(response =>
      this.dataPatient = response)
  }

  ngOnInit(): void {

  }

  getdata(name: any, lastname: any, account_state: any, tel: any, email: string, id: any, birthday: any, adresse: any, ssn: any, gender: any, photo: any, archived: any, added_date: any) {
    this.messageSuccess = ''
    this.dataPatient.name = name
    this.dataPatient.lastname = lastname
    this.dataPatient.account_state = account_state
    this.dataPatient.email = email
    this.dataPatient.tel = tel
    this.dataPatient.adresse = adresse
    this.dataPatient.added_date = added_date
    this.dataPatient.birthday = birthday
    this.dataPatient.ssn = ssn
    this.dataPatient.gender = gender
    this.dataPatient.photo = photo
    this.dataPatient.archived = archived
    this.dataPatient.id = id

  }

  updatenewstudent(f: any) {
    let data = f.value
    this.updateservice.updatePatient(this.dataPatient.id, data).subscribe(response => {
      let indexId = this.dataArray.findIndex((obj: any) => obj._id == this.dataPatient.id)

      this.dataArray[indexId].name = data.name
      this.dataArray[indexId].lastname = data.lastname
      this.dataArray[indexId].account_state = data.account_state
      this.dataArray[indexId].tel = data.tel
      this.dataArray[indexId].email = data.email
      this.dataArray[indexId].adresse = data.adresse
      this.dataArray[indexId].added_date = data.added_date
      this.dataArray[indexId].birthday = data.birthday
      this.dataArray[indexId].ssn = data.ssn
      this.dataArray[indexId].gender = data.gender
      this.dataArray[indexId].photo = data.photo
      this.dataArray[indexId].archived = data.archived
      this.dataArray[indexId].id = data.id



      this.messageSuccess = `this student ${this.dataArray[indexId].firstname} is updated`


    }, (err: HttpErrorResponse) => {

    })
  }


  /**
   * Function to update patient's information
   * @param f Patient form data
   */
  updProfil(f: any) {

    let data = f.value
    this.obsUpd = this.updateservice.updatePatient(this.id, data).subscribe(response => {
      location.reload();
      this.snackBar.open(" profile updated successfully " ,"×", {

        duration: 5000,

        // here specify the position

        verticalPosition: 'top',
        panelClass:'success'

      });

    },error=> this.snackBar.open(" profile not updated " ,"×", {

      duration: 5000,

      // here specify the position

      verticalPosition: 'top',
      panelClass:'error'

    }))

  }
    /**
   * Function to exit the profile editing
   */
  exit() {
    location.reload();
  }
  /**
   * Function called when the user confirms the action
   */
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(false);
  }
   /**
   * Function called when the user dismisses the action
   */
  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

 /**
   * Lifecycle hook - called before Angular destroys the component
   */
  ngOnDestroy(): void {
    this.obsGet.unsubscribe
    this.obsUpd.unsubscribe


  }
}
/**
 * Model for dialog data
 */
export class DialogModel {
  constructor(public title: string, public message: string) {
  }



}





