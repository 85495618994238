<div class="card-body">
  <form class="inscrit-content">
    <div class="form-row">
      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Nom" type="text" class="form-control" />
          <mat-error>Nom obligatoire</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Prénom" type="text" class="form-control" />
          <mat-error>Nom obligatoire</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field class="profile-detail">
          <mat-label>Date de naissance</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Date de naissance (dd/MM/yyyy)" class="form-control" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>Date invalide</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="SSN" type="number" class="form-control" />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-3 col-12">
        <mat-form-field class="profile-detail">
          <mat-label>Adresse</mat-label>
          <input matInput placeholder="Adresse" class="form-control" />
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Numéro de téléphone" type="number" class="form-control" />
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Poids" type="number" class="form-control" />
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Taille" type="number" class="form-control" />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Email" type="email" class="form-control" />
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="homme" class="form-control">Homme</mat-radio-button>
          <mat-radio-button value="femme" class="form-control">Femme</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="col-md-3 col-12">
        <mat-form-field>
          <input matInput placeholder="Mot de passe" type="password" class="form-control" />
        </mat-form-field>
      </div>

      <div class="col-md-3 col-12">
        <button class="btn btn-primary" type="submit">Submit</button>
      </div>
    </div>
  </form>
</div>
