<div class="auth-dialog-content" [ngStyle]="{ width: isLogin ? 'auto' : '100dvh' }" >
  <mat-tab-group class="auth-tab-group custom-tab-group" mat-stretch-tabs (selectedTabChange)="onTabChange($event)" >
    <mat-tab label="{{ 'AUTH.IDENTIFIER2' | translate }}" >
      <div class="mat-tab-content" >
        <mat-card-content class="mat-card-content" >
          <div class="auth-toggle">
            <mat-button-toggle-group class="auth-toggle-group" aria-label="User Role Selection" [(value)]="selectedVal"
              (change)="onValChange(selectedVal)">
              <mat-button-toggle value="Professionnel" (click)="onReset()">
                <i class="fa fa-user-md"></i>{{ "AUTH.PROF" | translate }}
              </mat-button-toggle>
              <mat-button-toggle value="Patient" (click)="onReset()">
                <i class="fa fa-user-injured"></i>{{ "AUTH.PAT" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="auth-form" [ngSwitch]="selectedVal">
            <div *ngSwitchCase="'Professionnel'">
              <form [formGroup]="loginFormPro" (ngSubmit)="loginPro(loginFormPro)" class="auth-form-group">
                <mat-form-field class="profile-detail">
                  <input matInput placeholder="Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPro.email.errors }">
                  <mat-error *ngIf="flogPro.email.invalid">Email requis</mat-error>
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>

                <mat-form-field class="profile-detail">
                  <input matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && flogPro.password.errors }">
                  <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="flogPro.password.invalid">Mot de passe requis </mat-error>
                </mat-form-field>

                <div class="forgot-password-link">
                  <a routerLink="/forgot-password">{{ "UPDATEMOT.MO" | translate }}</a>
                </div>
                <button mat-raised-button color="primary" class="auth-button" type="submit">
                  {{ "AUTH.SIGNIN" | translate }}
                </button>
                <!-- <button class="google-login-button" (click)="signInWithGoogle()"> -->
                  <!-- <i class="fab fa-google"></i> Login with Google -->
                <!-- </button> -->
                
              </form>
            </div>

            <div *ngSwitchCase="'Patient'">
              <form [formGroup]="loginFormPat" (ngSubmit)="loginPat(loginFormPat)" class="auth-form-group" >
                <mat-form-field class="profile-detail" >
                  <input matInput placeholder="Email" formControlName="email" class="form-control" [ngClass]="{'is-invalid': submitted && flogPat.email.errors}" />
                  <mat-error *ngIf="flogPat.email.invalid">Email requis</mat-error>
                  <mat-icon matSuffix>email</mat-icon>

                </mat-form-field>
                <mat-form-field class="profile-detail">
                  <input matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'"
                    formControlName="password" class="form-control" [ngClass]="{'is-invalid': submitted && flogPat.password.errors}" />
                    <mat-icon (click)="myFunction()" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="flogPat.password.invalid">Mot de passe requis</mat-error>
                </mat-form-field>
                <div class="forgot-password-link">
                  <a routerLink="/forgot-password">{{ "UPDATEMOT.MO" | translate }}</a>
                </div>
                <button mat-raised-button color="primary" class="auth-button" type="submit">
                  {{ "AUTH.SIGNIN" | translate }}
                </button>
                <!-- <button class="google-login-button" (click)="signInWithGoogle()"> -->
                  <!-- <i class="fab fa-google"></i> Login with Google -->
                <!-- </button> -->
                
                



              </form>
            </div>
          </div>
        </mat-card-content>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'AUTH.INSCRIRE' | translate }}">
      <div class="mat-tab-content">
        <mat-card-content class="mat-card-content">
          <div class="auth-toggle">
            <mat-button-toggle-group class="auth-toggle-group" aria-label="User Role Selection" [(value)]="selectedVal2"
              (change)="onValChange2(selectedVal2)">
              <mat-button-toggle value="Professionnel" (click)="onReset()">
                <i class="fa fa-user-md"></i>{{ "AUTH.PROF" | translate }}
              </mat-button-toggle>
              <mat-button-toggle value="Patient" (click)="onReset()">
                <i class="fa fa-user-injured"></i>{{ "AUTH.PAT" | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>


          <div class="auth-form" [ngSwitch]="selectedVal2" style="margin-bottom: 15dvh">
            <div *ngSwitchCase="'Professionnel'">
              <form [formGroup]="registerFormPro" (ngSubmit)="registerPro(registerFormPro, 2)">
                <div class="form-row">
                  <!-- Name Field -->
                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.NOM" | translate }}</mat-label>

                    <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" type="text" formControlName="name"
                      class="form-control" [ngClass]="{
                        'is-invalid': f.name.touched && f.name.errors
                      }" />

                    <mat-error *ngIf="f.name.invalid && f.name.touched">
                      <span *ngIf="f.name.errors?.required">{{
                        "Nom obligatoire"
                        }}</span>
                      <span *ngIf="f.name.errors?.minlength">{{
                        "Minimum 2 caractères"
                        }}</span>
                    </mat-error>
                  </mat-form-field>

                  <!-- Last Name Field -->
                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.PRENOM" | translate }}</mat-label>

                    <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" type="text" formControlName="lastname"
                      class="form-control" [ngClass]="{
                        'is-invalid': f.name.touched && f.name.errors
                      }" />

                    <mat-error *ngIf="f.lastname.invalid && f.lastname.touched">
                      <span *ngIf="f.lastname.errors?.required">{{
                        "Prénom obligatoire"
                        }}</span>
                      <span *ngIf="f.lastname.errors?.minlength">{{
                        "Minimum 2 caractères"
                        }}</span>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-row">
                  <div class="profile-detail profile-detail-date">
                    <label>{{ "AUTH.DATEN" | translate }}</label>
                    <input type="date" formControlName="birthday" class="form-control"
                      [ngClass]="{'is-invalid': f.birthday.touched && f.birthday.errors}" 
                      placeholder="{{ 'AUTH.DATEN' | translate }}" />
                    
                    <div *ngIf="f.birthday.invalid && f.birthday.touched" class="error">
                      <span *ngIf="f.birthday.errors?.required">
                        {{ "Date de naissance est obligatoire MM/dd/yyyy" }}
                      </span>
                      <span *ngIf="f.birthday.errors?.pastDate">Date invalide</span>
                    </div>
                  </div>
                  
                  <mat-form-field appearance="fill">
                    <mat-label>{{ "AUTH.METIER" | translate }}</mat-label>
                    <mat-select formControlName="job" [(ngModel)]="jobs[0].viewValue">
                      <mat-option *ngFor="let job of jobs" [value]="job.viewValue">
                        {{ job.viewValue }}
                      </mat-option>
                    </mat-select>
                    <!--    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                      <div *ngIf="f.job.errors.required">travail obligatoire</div>
                    </div> -->
                  </mat-form-field>

                 
                </div>

                <div class="form-row">
                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>

                    <input matInput placeholder="{{ 'AUTH.ADDRESS' | translate }}" formControlName="adresse"
                      class="form-control" [ngClass]="{
                        'is-invalid': f.adresse.touched && f.adresse.errors
                      }" />

                    <!-- Message d'erreur pour Validators.minLength -->
                    <mat-error *ngIf="f.adresse.errors?.minlength">
                      L'adresse doit comporter au moins 2 caractères
                    </mat-error>
                    <mat-error *ngIf="f.adresse.errors?.required">
                      L'adresse est obligatoire
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="profile-detail">
                    <mat-label>FAX</mat-label>
                    <input matInput placeholder="FAX" formControlName="fax" class="form-control" type="number"
                      [ngClass]="{
                        'is-invalid': f.fax.touched && f.fax.errors
                      }" />

                    <!-- Custom error message for fax validation -->
                    <div *ngIf="f.fax.invalid && f.fax.touched" class="invalid-feedback">
                      <div *ngIf="f.fax.errors?.required">
                        fax est obligatoire
                      </div>
                      <div *ngIf="f.fax.errors?.telLength">
                        Veuillez entre entre 8 et 16 chiffres
                      </div>
                    </div>
                  </mat-form-field>
                </div>

                <div class="form-row">
             
                  <mat-form-field class="profile-detail">
                    <div class="country-code-wrapper">
                      <mat-select class="country-code" placeholder="{{ 'AUTH.COUNTRY' | translate }}" [formControl]="selectedCountryCodeControl" (selectionChange)="onCountryCodeChange($event)">
                        <mat-option value="" disabled>{{ 'Select code' | translate }}</mat-option>
                        <mat-option *ngFor="let country of countries" [value]="country.code">
                          {{ country.code }} ({{ country.name }})
                        </mat-option>
                      </mat-select>
                    </div>
                  </mat-form-field>


                  <mat-form-field class="profile-detail">
                    <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                    <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" formControlName="tel"
                      class="form-control" type="number" [ngClass]="{
                        'is-invalid': f.tel.touched && f.tel.errors
                      }" />

                    <!-- Custom error message for tel validation -->
                    <div *ngIf="f.tel.invalid && f.tel.touched" class="invalid-feedback">
                      <div *ngIf="f.tel.errors?.required">
                        Le numéro de téléphone est obligatoire
                      </div>
                      <div *ngIf="f.tel.errors?.telLength">
                        Veuillez entre entre 8 et 16 chiffres
                      </div>
                    </div>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                    <mat-label>{{ "AUTH.TITRE" | translate }}</mat-label>
                    <mat-select formControlName="title" [(ngModel)]="titre[0].viewValue">
                      <mat-option *ngFor="let food of titre" [value]="food.viewValue">
                        {{ food.viewValue }}
                      </mat-option>
                    </mat-select>
                    <!--    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                      <div *ngIf="f.job.errors.required">travail obligatoire</div>
                    </div> -->
                  </mat-form-field>
                </div>

                <div class="form-row">
                  <mat-form-field>
                    <input matInput placeholder="RPPS" type="number" formControlName="rpps" class="form-control"
                      [ngClass]="{
                        'is-invalid': f.rpps.invalid && f.rpps.touched
                      }" />

                    <div *ngIf="f.rpps.invalid && f.rpps.touched" class="invalid-feedback">
                      <div *ngIf="f.rpps.errors?.required">
                        Le RPPS est obligatoire
                      </div>
                      <div *ngIf="f.rpps.errors?.rppsLength">
                        Le RPPS doit comporter 12 chiffres
                      </div>
                    </div>
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput placeholder="{{ 'AUTH.EMAIL' | translate }}" type="email" formControlName="email"
                      class="form-control" [ngClass]="{
                        'is-invalid': f.email.invalid && f.email.touched
                      }" />

                    <div *ngIf="f.email.invalid && f.email.touched" class="invalid-feedback">
                      <div *ngIf="f.email.errors?.required">
                        Email est obligatoire
                      </div>
                      <div *ngIf="f.email.errors?.email">
                        Adresse email invalide
                      </div>
                    </div>
                  </mat-form-field>
                </div>

                <div class="form-row">
                  <mat-form-field>
                    <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}"
                      [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control" [ngClass]="{
                        'is-invalid': f.password.invalid && f.password.touched
                      }" />

                    <mat-hint align="end" aria-live="polite">
                      {{ password.value.length }} / {{ passwordComponent.max }}
                    </mat-hint>
                    <mat-icon (click)="myFunction()" matSuffix>{{
                      hide ? "visibility_off" : "visibility"
                      }}</mat-icon>

                    <mat-error *ngIf="!f.password.valid && f.password.touched">
                      {{ getErrorPassword() }}
                    </mat-error>

                    <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)"
                      [password]="password.value">
                    </mat-password-strength>

                    <div *ngIf="f.password.invalid && f.password.touched" class="invalid-feedback">
                      <div *ngIf="f.password.errors?.required">
                        Mot de passe est obligatoire
                      </div>
                      <div *ngIf="f.password.errors?.minlength">
                        8 caractères
                      </div>
                      <div *ngIf="f.password.errors?.pattern">
                        Le mot de passe doit être plus complexe
                        [a-z][A-Z][@$!%*?&]
                      </div>
                    </div>
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput placeholder="{{ 'AUTH.CFM' | translate }}" [type]="hide ? 'password' : 'text'"
                      formControlName="confirmPassword" class="form-control" [ngClass]="{
                        'is-invalid': submitted && f.confirmPassword.errors
                      }" />

                    <mat-error *ngIf="
                        registerFormPro.controls['confirmPassword'].value !=
                        registerFormPro.controls['password'].value
                      ">
                      <div>Confirmer votre mot de passe</div>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="form-group form-check">
                  <mat-icon matSuffix (click)="openAlertDialog()">add</mat-icon>

                  <label for="acceptTerms" class="form-check-label" (click)="openAlertDialog()">{{ "AUTH.ACCEPTT" |
                    translate }}</label>
                  <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                    Accepter Ts & Cs is obligatoire
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-primary mr-1" type="submit" [disabled]="!acceptTerme || !registerFormPro">
                    {{ "AUTH.INSCRIRE" | translate }}
                  </button>
                </div>

                <br />
                <br />
              </form>
            </div>

              <div *ngSwitchCase="'Patient'">
                <form [formGroup]="registerFormPat" (ngSubmit)="registerPat(registerFormPat, 1)">
                  <div class="form-row">
                    <!-- Name Field -->
                    <mat-form-field class="profile-detail">
                      <input matInput placeholder="{{ 'AUTH.NOM' | translate }}" formControlName="name"
                        [ngClass]="{ 'is-invalid': fPat.name.touched && fPat.name.errors }" (blur)="formatInput(registerFormPat, 'name')" />
                      <mat-error *ngIf="fPat.name.invalid && fPat.name.touched">
                        <span *ngIf="fPat.name.errors?.required">Nom obligatoire</span>
                        <span *ngIf="fPat.name.errors?.minlength">Minimum 2 caractères</span>
                      </mat-error>
                    </mat-form-field>
              
                    <!-- Lastname Field -->
                    <mat-form-field class="profile-detail">
                      <input matInput placeholder="{{ 'AUTH.PRENOM' | translate }}" formControlName="lastname"
                        [ngClass]="{ 'is-invalid': fPat.lastname.touched && fPat.lastname.errors }" (blur)="formatInput(registerFormPat, 'lastname')" />
                      <mat-error *ngIf="fPat.lastname.invalid && fPat.lastname.touched">
                        <span *ngIf="fPat.lastname.errors?.required">Nom obligatoire</span>
                        <span *ngIf="fPat.lastname.errors?.minlength">Minimum 2 caractères</span>
                      </mat-error>
                    </mat-form-field>
                  </div>
              
                  <div class="form-row" >
                 
                       <!-- Address Field -->
                       <mat-form-field class="profile-detail">
                        <mat-label>{{ "AUTH.ADDRESS" | translate }}</mat-label>
                        <input matInput placeholder="{{ 'AUTH.ADDRESS' | translate }}" formControlName="adresse"
                          [ngClass]="{ 'is-invalid': fPat.adresse.touched && fPat.adresse.errors }" />
                        <mat-error *ngIf="fPat.adresse.errors?.minlength">L'adresse doit comporter au moins 2 caractères</mat-error>
                        <mat-error *ngIf="fPat.adresse.errors?.required">L'adresse est obligatoire</mat-error>
                      </mat-form-field>

              
                    <!-- SSN Field -->
                    <mat-form-field class="profile-detail" >
                      <input matInput placeholder="SSN" formControlName="ssn"
                        [ngClass]="{ 'is-invalid': fPat.ssn.invalid && fPat.ssn.touched }" />
                      <div *ngIf="fPat.ssn.invalid && fPat.ssn.touched" class="invalid-feedback">
                        <div *ngIf="fPat.ssn.errors?.required">Le SSN est obligatoire</div>
                        <div *ngIf="fPat.ssn.errors?.ssnLength">Le SSN doit comporter 9 chiffres</div>
                        <div *ngIf="fPat.ssn.errors?.pattern">Uniquement des chiffres</div>
                      </div>
                    </mat-form-field>
              
                  
                  </div>
              
                  <div class="form-row">
                   

                         <!-- Birthday Field -->
                    <div class="profile-detail profile-detail-date ">
                      <label>{{ "AUTH.DATEN" | translate }}</label>
                      <input type="date" formControlName="birthday" class="form-control"
                        [ngClass]="{'is-invalid': fPat.birthday.touched && fPat.birthday.errors}" 
                        placeholder="{{ 'AUTH.DATEN' | translate }}" />
                      
                      <div *ngIf="fPat.birthday.invalid && fPat.birthday.touched" class="error">
                        <span *ngIf="fPat.birthday.errors?.required">
                          {{ "Date de naissance est obligatoire MM/dd/yyyy" }}
                        </span>
                        <span *ngIf="fPat.birthday.errors?.pastDate">Date invalide</span>
                      </div>
                    </div>
                    <!-- Gender Field -->
                 <!-- HTML Structure -->
<div class="profile-detail-gender">
  <mat-radio-group aria-label="Select an option" formControlName="gender" class="radio-group">
    <mat-radio-button value="homme" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }">
      {{ 'AUTH.SEXEH' | translate }}
    </mat-radio-button>
    <mat-radio-button value="femme" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && fPat.gender.errors }">
      {{ 'AUTH.SEXEF' | translate }}
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="submitted && fPat.gender.errors" class="invalid-feedback">
  </div>
</div>

                    

              
                  
                  </div>
              
                  <div class="form-row">
                    <mat-form-field class="profile-detail">
                      <div class="country-code-wrapper">
                        <mat-select class="country-code" placeholder="{{ 'AUTH.COUNTRY' | translate }}" [formControl]="selectedCountryCodeControl" (selectionChange)="onCountryCodeChange($event)">
                          <mat-option value="" disabled>{{ 'Select code' | translate }}</mat-option>
                          <mat-option *ngFor="let country of countries" [value]="country.code">
                            {{ country.code }} ({{ country.name }})
                          </mat-option>
                        </mat-select>
                      </div>
                    </mat-form-field>

                      <!-- Tel Field -->
                      <mat-form-field class="profile-detail">
                        <mat-label>{{ "AUTH.TELE" | translate }}</mat-label>
                        <input matInput placeholder="{{ 'AUTH.TELE' | translate }}" formControlName="tel"
                          [ngClass]="{ 'is-invalid': fPat.tel.touched && fPat.tel.errors }" />
                        <div *ngIf="fPat.tel.invalid && fPat.tel.touched" class="invalid-feedback">
                          <div *ngIf="fPat.tel.errors?.required">Le numéro de téléphone est obligatoire</div>
                          <div *ngIf="fPat.tel.errors?.telLength">Veuillez entre entre 8 et 16 chiffres</div>
                        </div>
                      </mat-form-field>

                    <!-- Weight Field -->
                    <mat-form-field class="profile-detail">
                      <input matInput placeholder="{{ 'AUTH.POID' | translate }}" formControlName="weight"
                        [ngClass]="{ 'is-invalid': fPat.weight.invalid && fPat.weight.touched }" />
                      <div *ngIf="fPat.weight.invalid && fPat.weight.touched" class="invalid-feedback">
                        <div *ngIf="fPat.weight.errors?.required">Le Poids est obligatoire</div>
                        <div *ngIf="fPat.weight.errors?.weightRange">La valeur saisie est invalide.</div>
                      </div>
                    </mat-form-field>
              
                
                  </div>
              
                  <div class="form-row">
                        <!-- Size Field -->
                        <mat-form-field class="profile-detail">
                          <input matInput placeholder="{{ 'AUTH.SIZE' | translate }}" formControlName="size"
                            [ngClass]="{ 'is-invalid': fPat.size.invalid && fPat.size.touched }" />
                          <div *ngIf="fPat.size.invalid && fPat.size.touched" class="invalid-feedback">
                            <div *ngIf="fPat.size.errors?.required">Taille obligatoire</div>
                            <div *ngIf="fPat.size.errors?.sizeRange">La valeur saisie est invalide.</div>
                          </div>
                        </mat-form-field>
                    <!-- Email Field -->
                    <mat-form-field class="profile-detail">
                      <input matInput placeholder="{{ 'AUTH.EMAIL' | translate }}" type="email" formControlName="email"
                        [ngClass]="{ 'is-invalid': fPat.email.invalid && fPat.email.touched }" />
                      <div *ngIf="fPat.email.invalid && fPat.email.touched" class="invalid-feedback">
                        <div *ngIf="fPat.email.errors?.required">Email est obligatoire</div>
                        <div *ngIf="fPat.email.errors?.email">Adresse email invalide</div>
                      </div>
                    </mat-form-field>
              
                
                  </div>
              
                  <div class="form-row">
                        <!-- Password Field -->
                        <mat-form-field class="profile-detail">
                          <input #password matInput placeholder="{{ 'AUTH.MOTDEPASS' | translate }}" [type]="hide ? 'password' : 'text'" formControlName="password"
                            [ngClass]="{ 'is-invalid': fPat.password.invalid && fPat.password.touched }" />
                          <mat-hint align="end" aria-live="polite">{{ password.value.length }} / {{ passwordComponent.max }}</mat-hint>
                          <mat-icon (click)="myFunction()" matSuffix>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                          <mat-error *ngIf="!fPat.password.valid && fPat.password.touched">{{ getErrorPassword() }}</mat-error>
                          <mat-password-strength #passwordComponent (onStrengthChanged)="onStrengthChanged($event)" [password]="password.value"></mat-password-strength>
                          <div *ngIf="fPat.password.invalid && fPat.password.touched" class="invalid-feedback">
                            <div *ngIf="fPat.password.errors?.required">Mot de passe est obligatoire</div>
                            <div *ngIf="fPat.password.errors?.minlength">8 caractères</div>
                            <div *ngIf="fPat.password.errors?.pattern">Le mot de passe doit être plus complexe [a-z][A-Z][@$!%*?&]</div>
                          </div>
                        </mat-form-field>
                    <!-- Confirm Password Field -->
                    <mat-form-field class="profile-detail">
                      <input matInput placeholder="{{ 'AUTH.CFM' | translate }}" [type]="hide ? 'password' : 'text'" formControlName="confirmPassword"
                        [ngClass]="{ 'is-invalid': submitted && fPat.confirmPassword.errors }" />
                      <mat-error *ngIf="registerFormPat.controls['confirmPassword'].value != registerFormPat.controls['password'].value">
                        <div>Confirmer votre mot de passe</div>
                      </mat-error>
                    </mat-form-field>
                  </div>
              



                  <div class="form-group form-check">
                    <mat-icon matSuffix (click)="openAlertDialog()">add</mat-icon>
  
                    <label for="acceptTerms" class="form-check-label" (click)="openAlertDialog()">{{ "AUTH.ACCEPTT" |
                      translate }}</label>
                    <div *ngIf="submitted && fPat.acceptTerms.errors" class="invalid-feedback">
                      Accepter Ts & Cs is obligatoire
                    </div>
                  </div>


                  
                  <div class="text-center">
                    <button class="btn btn-primary mr-1" type="submit" [disabled]="!acceptTerme" [mat-dialog-close]="true">
                      {{ "AUTH.INSCRIRE" | translate }}
                    </button>
                    <!-- <button mat-raised-button color="primary" (click)="signInWithGoogle()" type="submit" style="margin-right: 10px">{{ 'AUTH.CG' | translate }}</button> -->
                  </div>
                </form>
              </div>
            
          </div>
        </mat-card-content>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>